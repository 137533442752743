import { AuthGroupManager } from "@kasasa/fbase-components/lib";

export default class FormsAuthor {
	private $mgr: AuthGroupManager;

	constructor(authMgr: AuthGroupManager) {
		this.$mgr = authMgr;
	}

	canReadGlobal(): boolean {
		return this.$mgr.canRead('formsFirstroi');
	}

	canWriteGlobal(): boolean {
		return this.$mgr.canWrite('formsFirstroi');
	}

	canWriteClient(): boolean {
		return this.$mgr.canWrite('formsClient');
	}

	canReadClient(): boolean {
		return this.$mgr.canRead('formsClient');
	}

}