



















import Vue from 'vue';
import { FormNode } from '@/services/api';
import { Component, Prop, VModel } from 'vue-property-decorator';
import BaseProperties from '@/components/properties/BaseProperties.vue';
import SnippetSelect from '@/components/menus/SnippetSelect.vue';

@Component({
	components: {
		BaseProperties,
		SnippetSelect,
	}
})
export default class SnippetProperties extends Vue {
	@VModel() el !: FormNode;
	@Prop({default: true}) ro !: boolean; 
}
