import { FormNode, FormNodeFactory, FormNodeType } from '@/services/api';
import moment from 'moment';
import {v1 as uuid} from "uuid";

export enum HistoryState {
	live = "live",
	default = "default"
}

export interface Revision {
	id: string;
	formId: number | string | null;
	historyState: HistoryState | null;
	time: number;
	fkUser: string | number | null;
	rootNode: FormNode;
}

export function RevisionFactory(userId: string | number ): Revision {
	const newRootNode: FormNode = FormNodeFactory(FormNodeType.form);
	newRootNode.fkParent = -1; // back end may do this...

	// make first page
	const page: FormNode = FormNodeFactory(FormNodeType.page, 'New Page');
	page.fkParent = newRootNode.id;
	newRootNode.children.push(page);

	const rev = {
		id: uuid(),
		formId: null,
		historyState: null,
		time: moment(new Date).unix(),
		fkUser: userId,
		rootNode: newRootNode,
	} as Revision;

	return rev;
}