import { FormType } from './Form';

export default class FormFilter {
	private type = '';

	setType(type: FormType): FormFilter {
		this.type = type; 
		return this;	
	}

	getQuery(): FormFilterQuery {
		const q = {} as FormFilterQuery;
		if (this.type) {
			q.type = this.type;
		}
		return q;
	}
}

interface FormFilterQuery {
	type: string;
}