



































import Vue from "vue";
import { Component } from 'vue-property-decorator';
import Draggable, { MoveEvent } from 'vuedraggable';

import { FormNode, FormNodeFactory, FormNodeType } from '@/services/api';
import { FieldModules, FieldOption, FieldLibraryList } from './fields/index';

@Component({
	components: {
		...FieldModules,
		Draggable
	}
})
export default class FieldsLibrary extends Vue {

	fieldLibraryList = FieldLibraryList;

	getComponentType(type: FormNodeType): string {
		return type.charAt(0).toUpperCase() + type.slice(1);
	}

	exchange(o: FieldOption): FormNode {
		return FormNodeFactory(o.type, 'New ' + o.label, o.preset);
	}

	checkMove(evt: MoveEvent<FieldOption>): boolean {
		if (evt.draggedContext.element.type === 'section'
				&& (evt.to.classList.contains('implicit-section') || evt.to.classList.contains('section-wrapper'))) {
			return false;
		}
		return true;
	}
}
