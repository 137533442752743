import { render, staticRenderFns } from "./FormListTable.vue?vue&type=template&id=cf01e788&scoped=true&"
import script from "./FormListTable.vue?vue&type=script&lang=ts&"
export * from "./FormListTable.vue?vue&type=script&lang=ts&"
import style0 from "./FormListTable.vue?vue&type=style&index=0&id=cf01e788&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cf01e788",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VCol,VContainer,VDataTable,VRow,VSwitch,VTextField})
