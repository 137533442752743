







































import Vue from 'vue';
import { FormNode } from '@/services/api';
import { Component, Prop, VModel } from 'vue-property-decorator';

import Editor from '@tinymce/tinymce-vue';
import { tinyMce } from '@/types/TinyMce';

@Component({
	components: {
		Editor
	}
})
export default class Content extends Vue {
	@VModel() el !: FormNode;
	@Prop({default: true}) readonly ro !: boolean;

	tiny = false;
	tinyMce = tinyMce;

	get required(): boolean {
		this.el;
		return !this.el.optional;
	}

	set required(val: boolean) {
		this.el.optional = !val;
	}

	get hasExtras(): boolean {
		return !!this.$slots.extras;
	}
}
