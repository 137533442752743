




import Vue from "vue";
import { Component} from 'vue-property-decorator';

@Component({
	components: {
	}
})
export default class AlphaSpaceValue extends Vue {
	get label(): string {
		return 'is alpha characters with spaces';
	}
}
