




































































































import Vue from "vue";
import { Component, Prop, VModel } from 'vue-property-decorator';
import moment from 'moment';
import { Location } from "vue-router/types/router";

import { RouteName } from "@/router";
import { HistoryState, Revision } from "@/services/api";
import { KSpinner } from '@kasasa/fbase-components';

@Component({
	components: {
		KSpinner
	}
})
export default class FormHistory extends Vue {
	@Prop({default: () => []}) history!: Revision[];
	@VModel({type: Boolean, default: false}) displayModal!: boolean;
	@Prop({ default: false }) readonly isGlobal !: boolean;
	@Prop() clientId !: string;
	@Prop({default: false}) isLoaded !: boolean;

	search = '';

	searchHighlight(value: string|number): string | number  {
		if (!this.search.length || value === null) {
			return value;
		}
		
		return value.toString().replace(
			RegExp(this.search, 'ig'),
			(matchText) => '<strong style="color: #000;">' + matchText + '</strong>'
		);
	}

	convertTime(time: number): string {
		return moment.unix(time).format('MM/DD/YYYY h:mm A');
	}

	handleRowClick(row: Revision): void {
		if (row.historyState === HistoryState.live) {
			return;
		}
		const route = {
			params: {
				revId: row.id,
				clientId: this.clientId,
				formId: row.formId
			}, 
			name: this.isGlobal ? RouteName.GLOBAL_HISTORY : RouteName.CLIENT_HISTORY
		} as Location;
		this.$router.push(route);
	}

}
