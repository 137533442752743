









import Vue from 'vue';
import { FormNode, getElementConfig, PropertiesType } from '@/services/api';
import { Component, Prop, VModel } from 'vue-property-decorator';
import * as properties from '@/components/properties';


@Component({
	components: { ...properties }
})
export default class Properties extends Vue {
	@VModel() el !: FormNode;
	@Prop({default: true}) ro !: boolean;

	get propertiesComponent(): PropertiesType | undefined {

		const cfg = getElementConfig(this.el.type);
		if (cfg) {
			if (cfg.properties) {
				return cfg.properties;
			} else {
				return PropertiesType.BASE;
			}
		}
		return PropertiesType.DEPRECATED;
	}
}
