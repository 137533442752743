


























































import Vue from "vue";
import { Component, Prop } from 'vue-property-decorator';
import draggable from "vuedraggable";
import { SortableEvent } from 'sortablejs';
import { FormNode } from "@/services/api";
import { KCardHeaderBtn} from '@kasasa/fbase-components';
import { namespace } from 'vuex-class';

import PageListItem from '@/components/pages/PageListItem.vue';

const formState = namespace('form');

@Component({
	components: {
		draggable,
		KCardHeaderBtn,
		PageListItem
	}
})
export default class PagesPanel extends Vue {
	@Prop({ default: false }) ro!: boolean;
	@formState.State('currentPage') page !: FormNode;
	@formState.Mutation setCurrentPage !: (page: FormNode) => void;
	@formState.Action resetCurrentPage !: () => Promise<void>;
	@formState.Action addNewPage !: () => Promise<void>;
	@formState.Action deletePage !: (page: FormNode) => Promise<void>;
	@formState.Getter formPages !: FormNode[];
	@formState.Mutation setPages !: (pages: FormNode[]) => void;

	get pages(): FormNode[] {
		return this.formPages;
	}

	set pages(pages: FormNode[]) {
		this.setPages(pages);
	}

	get currentPage(): FormNode {
		return this.page;
	}

	set currentPage(page: FormNode) {
		this.setCurrentPage(page);
	}

	onChoose(e: SortableEvent): void {
		if (e.oldIndex) {
			this.setCurrentPage(this.pages[e.oldIndex]);
		}
	}

	onSort(e: SortableEvent): void {
		if (e.newIndex) {
			this.setCurrentPage(this.pages[e.newIndex]);
		}
	}

	resetSelected(): void {
		// if someone deletes all pages??
		if (this.pages.length === 0) {
			this.resetCurrentPage();
			return;
		}
		this.setCurrentPage(this.pages[0]);
	}
}
