import {FormSpecification, FormHook, FormNodeType, DeprecatedFormNodeType} from '@/services/api';
import {v1 as uuid} from "uuid";
import { merge } from 'lodash';

export interface FormNodeOption {
	label: string;
}

export interface FormNodePresets {
	beginWithEmptyOption?: boolean;
	label?: string | null;
	options?: FormNodeOption[];
	showInLeadManager?: boolean;
	showInLeadManagerAs?: string;
	optional?: boolean;
}

export interface FormNode {
	id: string | null;
	beginWithEmptyOption: boolean;
	children: FormNode[];
	conditions: FormSpecification[];
	content: string | null;
	cssClass: string | null;
	defaultMessage: string | null;
	demoValue: string | null;
	errorMessage: string | null;
	fkFormSnippet: string | number | null;
	fkFroiFormRevision: string | number | null;
	fkNormalField: string | number | null;
	fkNormalStep: string | number | null;
	fkParent: string | number | null;
	hideWhenInactive: boolean;
	inputmask: string | null;
	isEnabled: boolean;
	isPointOfNoReturn: boolean;
	label: string | null;
	optional: boolean;
	options: FormNodeOption[] | null;
	placeholderText: string | null;
	postHooks: FormHook[];
	randomizeList: boolean;
	shopAction5Tag: boolean;
	showInLeadManager: boolean;
	showInLeadManagerAs: string | null;
	successMessage: string | null;
	template: string | null;
	type: FormNodeType | DeprecatedFormNodeType;
	validators: FormSpecification[];
}

export function FormNodeFactory(type: FormNodeType, label?: string, presets?: FormNodePresets): FormNode {
	// template AKA variant
	const template = [FormNodeType.page, FormNodeType.implicitSection].includes(type) ? null : 'default';

	const node = {
		id: uuid(),
		beginWithEmptyOption: false,
		children: [],
		conditions: [],
		content: null,
		cssClass: null,
		defaultMessage: null,
		demoValue: null,
		errorMessage: null,
		fkFormSnippet: null,
		fkFroiFormRevision: null,
		fkNormalField: null,
		fkNormalStep: null,
		fkParent: null,
		hideWhenInactive: false,
		inputmask: null,
		isEnabled: true,
		isPointOfNoReturn: false,
		label: label || null,
		optional: false,
		options: [], 
		placeholderText: null,
		postHooks: [],
		randomizeList: false,
		shopAction5Tag: false,
		showInLeadManager: true,
		showInLeadManagerAs: null,
		successMessage: null,
		template: template,
		type: type,
		validators: []
	} as FormNode;

	if (!presets) {
		return node;
	}

	return merge(node, presets);
}