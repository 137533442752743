




import Vue from "vue";
import { Component } from 'vue-property-decorator';

@Component({
	components: {}
})
export default class NumericValue extends Vue {
	get label(): string {
		return 'is a number';
	}
}
