









import Vue from 'vue';
import { FormNode, SpecificationType } from '@/services/api';
import { Component, Prop, VModel } from 'vue-property-decorator';
import SpecificationsManager from '@/components/managers/SpecificationsManager.vue';


@Component({
	components: { SpecificationsManager }
})
export default class Activators extends Vue {
	@VModel() el !: FormNode;
	@Prop({default: true}) ro !: boolean;

	SpecificationType = SpecificationType;
}
