import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import { Form, FormNode, FormNodeFactory, FormNodeType, FormType, isPageOrSection } from '@/services/api';
import FormService from '@/services/FormService';
import FormFilter from '@/services/api/FormFilter';

export enum LoadStatus {
	loading = 'loading',
	set = 'set',
	unset = 'unset'
}

@Module({
	namespaced: true,
})
export default class FormStore extends VuexModule {

	form: Form = {} as Form;
	currentPage: FormNode | null = null;
	snippets: Form[] = [];
	snippetStatus: LoadStatus = LoadStatus.unset;

	get formPages(): FormNode[] {
		return this.form.revision.rootNode.children;
	}

	@Action
	init(form: Form): FormStore {
		this.context.commit('setForm', form);
		this.context.commit('setSnippetStatus', LoadStatus.unset);
		this.context.dispatch('fetchSnippets');
		return this;
	}

	

	@Mutation
	setForm(form: Form): void {
		this.form = form;
	}

	@Mutation
	setCurrentPage(currentPage: FormNode): void {
		this.currentPage = currentPage;
	}

	@Action
	resetCurrentPage(): void {
		if (this.formPages.length) {
			this.context.commit('setCurrentPage', this.formPages[0]);
		} else {
			this.context.commit('setCurrentPage', null);
		}
	}

	@Action
	addNewPage(): void {
		const page = FormNodeFactory(FormNodeType.page, 'New Page');
		this.form.revision.rootNode.children.push(page);
		this.context.commit('setCurrentPage', page);
	}

	@Mutation
	setPages(pages: FormNode[]): void {
		this.form.revision.rootNode.children = pages;
	}

	@Action
	deletePage(page: FormNode): void {
		this.context.commit('setPages', this.formPages.filter(h => { return h != page;}));
		this.context.dispatch('resetCurrentPage');
	}

	// Fields
	@Mutation
	appendFieldToCurrentPage(newFormNode: FormNode): void {
		if (!this.currentPage) {
			throw new Error('Not on a page.');
		}

		let appendFormNode = newFormNode;
		const sections = this.currentPage.children;

		if (sections.length == 0 && appendFormNode.type !== FormNodeType.section) {
			const section = FormNodeFactory(FormNodeType.implicitSection, 'Implicit Section');
			section.children.push(appendFormNode);
			appendFormNode = section;
		}

		const appendTo = isPageOrSection(appendFormNode.type) ? sections : sections[sections.length - 1].children;
		appendTo.push(appendFormNode);
	}

	// Snippets
	@Mutation
	setSnippets(snippets: Form[]): void {
		this.snippets = snippets;
	}

	@Mutation
	setSnippetStatus(status: LoadStatus): void {
		this.snippetStatus = status;
	}

	@Action({commit: 'setSnippets' })
	public async fetchSnippets(): Promise<Form[]> {
		if (this.snippetStatus !== LoadStatus.unset) {
			return this.snippets;
		}

		// call service
		const formService = new FormService;
		const formFilter  = new FormFilter().setType(FormType.snippet);

		this.context.commit('setSnippetStatus', LoadStatus.loading);
	
		return formService.findAll(Number(this.form.clientId), formFilter)
			.then(resp => {
				return resp.data.data || [];
			})
			.catch(() => {
				return [];
			})
			.finally(() => {
				this.context.commit('setSnippetStatus', LoadStatus.set);
			});
	}
}