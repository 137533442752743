






























































































import Vue from 'vue';
import { Component, Prop, PropSync } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';

import { Form as IForm } from '@/services/api/Form';
import FormListFilters from '@/components/FormListFilters.vue';

import ListTableOptions from '@/types/ListTableOptions';
import ListTableFilters from '@/types/ListTableFilters';
import { RouteName } from '@/router';

@Component({
	components: {
		FormListFilters,
	},
})
export default class FormListTable extends Vue {
	@Prop({ default: () => [] }) readonly forms!: Array<IForm>;
	@Prop({ default: false }) readonly isGlobal!: boolean;
	@Prop() readonly clientId!: string;
	@PropSync('options') tableOptions!: ListTableOptions;
	@PropSync('filters') tableFilters!: ListTableFilters;

	get filteredList(): Array<IForm> {
		return this.forms.filter((form: IForm) => {
			if (
				this.tableFilters.search &&
				!this.filterSearch(form, this.tableFilters.search)
			) {
				return false;
			}

			// extra filters from here on out
			if (!this.tableFilters.useExtraFilters) {
				return true;
			}

			// type must match, if set
			if (this.tableFilters.type && form.type !== this.tableFilters.type) {
				return false;
			}

			return true;
		});
	}

	searchHighlight(value: string | number): string | number {
		if (!this.tableFilters.search?.length || value === null) {
			return value;
		}
		return value
			.toString()
			.replace(
				RegExp(this.tableFilters.search, 'ig'),
				matchText => '<strong style="color: #000;">' + matchText + '</strong>',
			);
	}

	capitalize(str: string): string {
		if (!str) {
			return '';
		}
		return (str.charAt(0).toUpperCase()) + str.substring(1);
	}

	filterSearch(form: IForm, filterValue: string): boolean {
		return form.name
			? form.name.toLowerCase().indexOf(filterValue.toLowerCase()) != -1
			: false;
	}

	handleRowClick(row: IForm): void {
		if (!row.id) {
			return;
		}
		this.$router.push({
			name: this.isGlobal ? RouteName.GLOBAL_EDIT : RouteName.CLIENT_EDIT,
			params: {
				clientId: this.clientId,
				formId: (row.id).toString(),
			},
		});
	}

	clear(): void {
		this.$emit('clear');
	}

	headers: DataTableHeader[] = [
		{ text: 'Name', value: 'name', width: '70%', align: 'start' },
		{ text: 'Type', value: 'type', width: '30%', align: 'start' },
	];
}
