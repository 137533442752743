import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators';

import {
	ListTableFilters,
	ListTableFiltersFactory,
} from '@/types/ListTableFilters';
import {
	ListTableOptions,
	ListTableOptionsFactory,
} from '@/types/ListTableOptions';

@Module({
	namespaced: true,
})
export default class ListTableStore extends VuexModule {
	tableFilters: ListTableFilters = ListTableFiltersFactory(); 
	tableOptions: ListTableOptions = ListTableOptionsFactory(); 

	@Mutation
	SET_TABLE_FILTERS(fresh: ListTableFilters): void {
		this.tableFilters = fresh;
	}

	@Mutation
	SET_TABLE_OPTIONS(fresh: ListTableOptions): void {
		this.tableOptions = fresh;
	}

	@Action
	reset(): void {
		this.context.commit('SET_TABLE_FILTERS', ListTableFiltersFactory());
		this.context.commit('SET_TABLE_OPTIONS', ListTableOptionsFactory());
	}

	@Action
	clearExtraFilters(): void {
		this.context.commit('SET_TABLE_FILTERS', {
			...ListTableFiltersFactory(),
			search: this.tableFilters.search,
			useExtraFilters: this.tableFilters.useExtraFilters,
		} as ListTableFilters);
	}

	@Action
	setTableFilters(filters: ListTableFilters): void {
		this.context.commit('SET_TABLE_FILTERS', filters);
	}

	@Action
	setTableOptions(options: ListTableOptions): void {
		this.context.commit('SET_TABLE_OPTIONS', options);
	}
}
