


































































import { Component, Prop, VModel } from 'vue-property-decorator';
import { FormNode, FormSpecification, GuardType } from '@/services/api';
import { KCardHeaderBtn } from '@kasasa/fbase-components';
import { SpecificationType } from '@/services/api';
import Vue from "vue";
import SpecificationFactory from '@/services/api/FormSpecification';
import Specification from '@/components/specifications/Specification.vue';

@Component({
	components: {
		Specification,
		KCardHeaderBtn,
	}
})
export default class SpecificationsManager extends Vue {
	@VModel({default: () => { return {} as FormNode; }}) formNode!: FormNode;
	@Prop({default: SpecificationType.validator}) readonly selectType!: SpecificationType;
	@Prop({default: true}) readonly disabled!: boolean;

	get specifications(): FormSpecification[] {
		return this.isValidator ? this.formNode.validators : this.formNode.conditions;
	}

	get isValidator(): boolean {
		return this.selectType == SpecificationType.validator;
	}

	get hasSpecifications(): boolean {
		return this.specifications.length > 0;
	}

	addSpecification(): void {
		const guardType = this.isValidator ? GuardType.validator : GuardType.condition;
		const specification = SpecificationFactory(Number(this.formNode.id), guardType);
		this.specifications.push(specification);
	}

	deleteSpecification(index: number): void {
		this.specifications.splice(index, 1);
	}
}
