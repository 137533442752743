




























































import Vue from "vue";
import { Component, Prop, VModel, PropSync } from 'vue-property-decorator';

import { Form, FormNode, Revision, SpecificationType, Tab } from '@/services/api';
import * as Tabs from '@/components/modals/tabs/index';
import { namespace } from 'vuex-class';

const formStore = namespace('form');

@Component({
	components: {
		...Tabs,
	}
})
export default class PageSettings extends Vue {
	@VModel() localPage!: FormNode;
	@Prop({default: true}) readonly actionsDisabled!: boolean;
	@Prop({default: true}) readonly ro!: boolean;
	@formStore.State('form') readonly form !: Form;
	@PropSync('isDisplayed', { default: false }) displayModal!: boolean;

	// @todo: ?replace with vuex store?
	@Prop({default: () => ({})}) readonly currentPage!: FormNode;

	SpecificationType = SpecificationType;

	// starting with first tab.
	tab = 0;
	tabs: Tab[] = [
		Tab.PROPERTIES,
		Tab.ACTIVATORS,
		Tab.ACTIONS,
	];

	get revision(): Revision {
		return this.form.revision;
	}

	tabItemCount(tabComponent: Tab): number {
		let count = 0;

		switch(tabComponent) {
			case Tab.ACTIONS:
				count = this.currentPage.postHooks.length;
				break;

			case Tab.ACTIVATORS:
				count = this.currentPage.conditions.length;
				break;
		}

		return count;
	}
}
