


































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { mapState } from 'vuex';
import { namespace } from 'vuex-class';
import moment from 'moment';
import { Location } from 'vue-router';

import { KCardHeader, KCardFooter, KCardFooterBtn, KCardHeaderBtn, KSpinner }  from '@kasasa/fbase-components';
import { Alert, AuthGroupManager, Dialog, ExpandableFilter, KCrumb, NoticeClass, NoticeResponse, SessionData } from '@kasasa/fbase-components/lib';

import FormService from '@/services/FormService';
import { Revision, Form, HistoryState } from '@/services/api';

import FormHeader from '@/components/FormHeader.vue';
import FormEditor from '@/components/FormEditor.vue';
import { RouteName } from '@/router';
import FormsAuthor from '@/store/FormsAuthor';

const form = namespace('form');
const auth = namespace('auth');

@Component({
	components: {
		FormHeader,
		FormEditor,
		KCardFooter,
		KCardFooterBtn,
		KCardHeader,
		KCardHeaderBtn,
		KSpinner
	},
	computed: {
		...mapState(['auth'])
	}
})
export default class HistoryPage extends Vue {
	@auth.State('authManager') authManager!: AuthGroupManager;
	@auth.State('sessionData') sessionData !: SessionData;
	@Prop() clientId !: string;
	@Prop() formId !: string;
	@Prop() revId !: string;
	@Prop({ default: false }) readonly isGlobal !: boolean;

	@form.Action init !: (form: Form) => void;
	@form.Mutation setForm !: (form: Form) => void;
	@form.State	form !: Form;

	formSvc = new FormService(this.$store);
	revision: Revision = {} as Revision;
	isLoaded = false;
	overlay = false;

	get formsAuthor(): FormsAuthor {
		return new FormsAuthor(this.authManager);
	}

	get isOldRevision(): boolean {
		return this.localForm.revision.historyState !== HistoryState.live;
	}

	get localForm(): Form {
		return this.form;
	}

	set localForm(val: Form) {
		this.setForm(val);
	}

	get ro(): boolean {
		// check for isGlobal and perms
		if (this.isGlobal) {
			return !this.formsAuthor.canWriteGlobal();
		}
		return !this.formsAuthor.canWriteClient();
	}

	created(): void {
		this.isLoaded = false;
		this.formSvc.find(this.clientId, this.formId, (new ExpandableFilter))
			.then((resp) => {
				const localForm = resp.data.data;
				this.formSvc.findRevision(this.clientId, this.formId, this.revId)
					.then((resp) => {
						localForm.revision = resp.data.data;
						this.localForm = localForm;
						this.init(this.localForm);
						this.isLoaded = true;
					})
					.catch(e => {
						if (e.response.status === 404) {
							const unknown = new Alert(`There was a problem loading the revision.`, NoticeClass.INFO);
							unknown.setTimeout(6000);
							this.$store.dispatch('notices/add', unknown);
							this.goToEdit();
						}
					});
			})
			.catch(e => {
				if (e.response.status === 404) {
					const unknown = new Alert(`There was a problem loading the form.`, NoticeClass.INFO);
					unknown.setTimeout(6000);
					this.$store.dispatch('notices/add', unknown);
					this.goToFormList();
				}
			});
		
	}	
	
	get title(): string {
		return this.isLoaded ? moment.unix(this.localForm.revision.time).format('MM/DD/YYYY h:mm A') : 'Unknown';
	}

	get crumbs(): KCrumb[] {
		const crumbs = [];
		crumbs.push(
			{
				key: '0',
				text: 'Forms',
				disabled: false,
				link: true,
				exact: true,
				to: { name: this.isGlobal ? RouteName.GLOBAL_HOME : RouteName.CLIENT_HOME, params: this.$route.params }
			}
		);

		crumbs.push({
			key: '1',
			text: 'Edit',
			disabled: false,
			link: true,
			exact: true,
			to: { name: this.isGlobal ? RouteName.GLOBAL_EDIT : RouteName.CLIENT_EDIT, params: this.$route.params }
		});

		crumbs.push({
			key:'2',
			text: 'History',
			disabled: true
		});

		return crumbs;
	}

	cancelAction(): void {
		this.goToEdit();
	}

	goToEdit(): void {
		this.$router.push({ name: this.isGlobal ? RouteName.GLOBAL_EDIT : RouteName.CLIENT_EDIT, params: this.$route.params });
	}

	goToFormList(): void {
		const route = {
			params: this.$route.params,
			name: this.isGlobal ? RouteName.GLOBAL_HOME: RouteName.CLIENT_HOME,
		} as Location;
		this.$router.push(route);
	}

	async restoreForm(): Promise<void> {
		// setting here for now, backend UserIdRegistry will be implemented separately, then this will be redundant
		this.localForm.revision.fkUser = this.sessionData.id;

		const dialog = new Dialog(
			'Restore this version?',
			`Restoring this previous version of the ${this.localForm.type} "${this.localForm.name}" will overwrite the current version and become live. Are you sure?`,
			'Yes, Restore This Version'
		);

		dialog.setDeclineLabel("No, Don't Restore")
			.setDismissable(false);

		const res = await this.$store.dispatch('notices/add', dialog);
		switch (res) {
			case NoticeResponse.ACCEPT:
				this.overlay = true;
				this.formSvc.update(this.clientId, this.formId, this.localForm)
					.then(() => {

						const success = new Alert(`The historical version of ${this.localForm.name} has been successfully restored.`, NoticeClass.SUCCESS);
						success.setTimeout(6000);
						this.$store.dispatch('notices/add', success);

						// will send to FormPage.
						this.goToEdit();
					})
					.catch(() => {
						// might be 409, in which case we have a prepared snackbar
						const fail = new Alert(`There was an error restoring ${this.localForm.name}.`, NoticeClass.ERROR);
						fail.setTimeout(6000);
						this.$store.dispatch('notices/add', fail);
					})
					.finally(() => {
						this.overlay = false;
					});
				break;
			case NoticeResponse.DECLINE:
			default:
				// do nothing
				// the modal closes
				break;
		}
	}

}
