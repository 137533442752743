









































import Vue from 'vue';
import { Component, PropSync } from 'vue-property-decorator';

import ListTableFilters from '@/types/ListTableFilters';

@Component({
	components: {},
})
export default class FormListFilters extends Vue {
	@PropSync('filters') tableFilters!: ListTableFilters;
	
	typeOptions = [
		{ text: 'All', value: null },
		{ text: 'Form', value: 'form' },
		{ text: 'Snippet', value: 'snippet' },
	];

	clear(): void {
		this.$emit('clear');
	}
}
