
























import { FormNode, getElementConfig } from '@/services/api';
import Vue from 'vue';
import { Component, VModel } from 'vue-property-decorator';

import BaseElement from './BaseElement.vue';

@Component({
	components: {
		BaseElement
	}
})
export default class SelectElement extends Vue {
	@VModel() el !: FormNode;

	get selectedOption():string {
		const config = getElementConfig(this.el.type);

		// selected value
		if (config.data?.value) {
			return config.data.value as string;
		}

		// else options handling
		if (this.el.beginWithEmptyOption) {
			return '';
		}

		if (config.data?.firstOption) {
			return config.data.firstOption as string;
		}

		return this.el.options?.length ? this.el.options[0].label : '';
	}
}
