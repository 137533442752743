// type of specification
export enum SpecificationType {
	activator = 'activator',
	validator = 'validator',
}

// specification.type
export enum SpecType {
	notEmptyValue = 'notEmptyValue',
	valueLength = 'valueLength',
	lTValue = 'lTValue',
	gTEValue = 'gTEValue',
	integerRange = 'integerRange',
	containsValue = 'containsValue',
	inArrayValue = 'inArrayValue',
	notInArrayValue = 'notInArrayValue',
	emailValue = 'emailValue',
	alphaValue = 'alphaValue',
	alphaSpaceValue = 'alphaSpaceValue',
	integerValue  = 'integerValue',
	numericValue = 'numericValue',
	zipcodeValue = 'zipcodeValue',
	notPoBoxValue = 'notPoBoxValue',
	routingNumberValue = 'routingNumberValue',
	equalFields = 'equalFields',
	isAtLeast18YearsAgo = 'isAtLeast18YearsAgo',
	isLessThan100YearsAgo = 'isLessThan100YearsAgo',
	isFuture = 'isFuture',
	isPast = 'isPast',
	meridianLinkHasQuestions = 'meridianLinkHasQuestions',
}

export enum ConditionType {
	applicant = 'applicant',
	array = 'array',
	fieldList = 'fieldList',
	none = 'none',
	number = 'number',
	range = 'range',
	text = 'text',
}

export enum GuardType {
	validator = "validator",
	condition = "condition"
}

export enum ApplicantType {
	PRIMARY = "PRIMARY",
	JOINT = "JOINT"
}

export interface FormSpecification {
	id: number | null;
	fkFroiFormNode: number | null;
	type: SpecType;
	guardType: GuardType | null;
	relatedNode: number | null;
	min: number | null;
	max: number | null;
	length: number | null;
	value: string;
	applicantType: ApplicantType | null;
	num: number | null;
	field: number | null;
}

const SpecificationFactory = (formNodeId: number, guardType: GuardType): FormSpecification => {
	return {
		id: null,
		fkFroiFormNode: formNodeId,
		type: SpecType.notEmptyValue,
		guardType: guardType,
		relatedNode: null,
		min: null,
		max: null,
		length: null,
		value: '',
		applicantType: null,
		num: null,
	} as FormSpecification;
};

export default SpecificationFactory;