










import { FormNode } from '@/services/api';
import Vue from 'vue';
import { Component, VModel } from 'vue-property-decorator';

import BaseCheckboxElement from './BaseCheckboxElement.vue';

@Component({
	components: {
		BaseCheckboxElement
	}
})
export default class CheckboxGroupElement extends Vue {
	@VModel() el !: FormNode;

	get options(): string[] {
		return this.el.options?.map((option) => option.label) || [];
	}
}
