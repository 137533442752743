


























import { FormHook } from '@/services/api';
import Vue from "vue";
import { Component, VModel, Prop } from 'vue-property-decorator';
import { KText } from '@kasasa/fbase-components';
import { KInputValidate } from '@kasasa/fbase-components/lib';

@Component({
	components: {
		KText
	}
})
export default class SendEmail extends Vue {
	@VModel({ default: () => { return {}; }}) action !: FormHook;
	@Prop({ default: '' }) label!: string;
	@Prop({ default: true }) disabled!: boolean;

	get validateInputIsEmail(): KInputValidate[] {
		return [KInputValidate.isEmail()];
	}
}
