







































import Vue from 'vue';
import { Component, VModel, Prop } from 'vue-property-decorator';
import Draggable from 'vuedraggable';

import * as elements from '@/components/elements/index';
import { DeprecatedFormNodeType, FormNode, FormNodeType, getElementConfig } from '@/services/api';
import FieldSettings from '@/components/modals/FieldSettings.vue';

@Component({
	components: {...elements, Draggable, FieldSettings}
})
export default class Section extends Vue {
	@VModel() field !: FormNode;
	@Prop() position !: number;
	@Prop({ default: true}) ro!: boolean;

	displaySettingsModal = false;
	sectionCollapsed = false;

	get isSection(): boolean {
		const types: string[] = [FormNodeType.implicitSection, FormNodeType.section];
		return types.includes(this.field.type);
	}

	getRenderComponent(type: FormNodeType | DeprecatedFormNodeType): string {
		return getElementConfig(type).renderedElement;	
	}	
	
	deleteNode(node: FormNode): void {
		this.field.children = this.field.children.filter(h => { return h != node;});

		this.$nextTick();
	}

	toggleSection(): void {
		this.sectionCollapsed = !this.sectionCollapsed;
	}

}
