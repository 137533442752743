
type filterTypes = string | number | boolean | null;

interface ListTableFilters {
	[key: string]: filterTypes | Array<filterTypes>;
	// search string (null for no search)
	search: string | null;
	// advanced filters on / off (the grey block square thing)
	useExtraFilters: boolean;
	// null = all
	type: 'form' | 'snippet' | null;
}

const ListTableFiltersFactory = (): ListTableFilters => {
	return {
		search: null,
		useExtraFilters: false,
		type: null,
	} as ListTableFilters;
};

export default ListTableFilters;
export {
	ListTableFilters,
	ListTableFiltersFactory,
};

