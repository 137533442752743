






































import { FormNode } from '@/services/api';
import Vue from 'vue';
import { Component, VModel, Prop } from 'vue-property-decorator';

import BaseElement from './BaseElement.vue';

@Component({
	components: {
		BaseElement
	}
})
export default class BaseCheckboxGroupElement extends Vue {
	@VModel() el !: FormNode;
	@Prop({ default: () => [] }) options !: string[];
	@Prop({ default: false }) isCheckboxGroup !: boolean;

	get displayOptions(): string[] {
		return !this.isCheckboxGroup ? [this.el.label || ''] : this.options;
	}
}
