import {Revision, RevisionFactory } from '@/services/api';

export enum FormType {
	snippet = "snippet",
	form = "form"
}

export interface Form {
	id: number | string | null;
	clientId: number | string | null;
	name?: string | null;
	type: FormType | null;
	isDeleted: boolean;
	revision: Revision;
}

export function FormFactory(clientId: string, userId: string | number, type?: FormType): Form {

	const form = {
		id: null,
		name: null,
		type: type,
		clientId: clientId,
		revision: RevisionFactory(userId),
		isDeleted: false
	} as Form;
	
	return form;
}