




















































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import Draggable from 'vuedraggable';

import { DeprecatedFormNodeType, FormNode, FormNodeFactory, FormNodeType, isImplicit } from '@/services/api';
import { SortableEvent } from 'sortablejs';
import FormSection from './Section.vue';
import ImplicitSection from './ImplicitSection.vue';
import { namespace } from 'vuex-class';

const formState = namespace('form');

@Component({
	components: {
		Draggable,
		FormSection,
		ImplicitSection
	}
})
export default class FieldsPanel extends Vue {
	@Prop({ default: true }) ro!: boolean;
	@formState.State currentPage !: FormNode;

	added(e: SortableEvent): void {
		// yeah! typescript
		if (e.newIndex !== undefined) {
			const newNode = this.currentPage.children[e.newIndex];
			// do the implicit section check and wrap here!
			if (!isImplicit(newNode.type) && newNode.type !== FormNodeType.section) {
				const siblingIndex = this.getNearestImplicit(e.newIndex);
				if (siblingIndex === -1) {
					const iSection = this.buildImplicitSection(newNode);
					this.$set(this.currentPage.children, e.newIndex, iSection);
				} else {
					const newSib = this.currentPage.children[siblingIndex];
					if (siblingIndex > e.newIndex) {
						// implicit section is below new, so put at top
						newSib.children.unshift(newNode);
					} else {
						// implicit sibling is above, so put at bottom
						newSib.children.push(newNode);
					}
					this.$set(this.currentPage.children, siblingIndex, newSib);
					this.$delete(this.currentPage.children, e.newIndex);
				}
			}
		}
		this.cleanImplicit();
	}

	cleanImplicit(): void {
		let sibling: FormNodeType | DeprecatedFormNodeType | '' = '';
		this.currentPage.children = this.currentPage.children.filter((section: FormNode, index: number) => {
			if (isImplicit(section.type)) {
				if (sibling === FormNodeType.implicitSection && section.children.length > 0) {
					const siblingIndex = index - 1;
					this.currentPage.children[siblingIndex].children = this.currentPage.children[siblingIndex].children.concat(section.children);
					return false;
				}
				sibling = section.type;
				return section.children.length > 0;
			}
			sibling = section.type;
			return true;
		});
	}

	getNearestImplicit(newIndex: number): number {
		// look for previous
		const sibling = -1;

		const nextSib: FormNode | undefined = this.currentPage.children[newIndex + 1];
		const previousSib: FormNode | undefined = this.currentPage.children[newIndex - 1];

		// check previous
		if (previousSib && isImplicit(previousSib.type)) {
			return newIndex - 1;
		}

		// check next
		if (nextSib && isImplicit(nextSib.type)) {
			return newIndex + 1;
		}

		return sibling;
	}

	buildImplicitSection(node: FormNode): FormNode {
		const wrapper = FormNodeFactory(FormNodeType.implicitSection, 'Implicit Section');
		wrapper.children.push(node);
		return wrapper;
	}

	deleteSection(section: FormNode): void {
		this.currentPage.children = this.currentPage.children.filter(s => { return s != section;});
	}

}
