
















































import Vue from "vue";
import { Component, Prop, VModel } from 'vue-property-decorator';
import { KReadOnly } from '@kasasa/fbase-components';
import { FormSpecification } from '@/services/api';
import Specification from '../Specification.vue';

@Component({
	components: {
		KReadOnly,
	}
})
export default class TextInput extends Vue {
	@VModel({default: () => ({} as Specification) }) specification!: FormSpecification;
	@Prop({default: true}) readonly disabled!: boolean;

	get value1(): string[] {
		return this.specification.value && this.specification.value.length ? this.specification.value.split('||'): [];
	}

	set value1(value: string[]) {
		this.specification.value = value ? value.join('||') : '';
	}

	removeTag (item: string): void {
		const newValue = this.value1;
		this.value1.splice(this.value1.indexOf(item), 1);
		this.value1 = newValue;
	}
}
