























import Vue from "vue";
import { Component, Prop } from 'vue-property-decorator';
import BaseField from './BaseField.vue';
import { FieldOption } from '.';


@Component({
	components: {
		BaseField
	}
})
export default class RadioGroupCustom extends Vue {
	@Prop({ default: {} }) field!: FieldOption;
	@Prop({ default: true }) disabled!: boolean;
}
