import { AxiosResponse } from 'axios';
import { NormalStep, NormalField } from '@/services/api';
import { FBaseService, ResponseEnvelope } from '@kasasa/fbase-components/lib';

export default class NormalService extends FBaseService {
	findAllNormalSteps(): Promise<AxiosResponse<ResponseEnvelope<NormalStep>>> {
		return this.apiClient.get(`/api/inmo-form/client/normal-steps`);
	}

	findAllNormalFields(): Promise<AxiosResponse<ResponseEnvelope<NormalField>>> {
		return this.apiClient.get(`/api/inmo-form/client/normal-fields`);
	}

	protected async handleForbidden(): Promise<void> {
		// fail silently
		return;
	}

	protected async handleUnauthenticated(): Promise<void> {
		// fail silently
		return;
	}

	protected async hanldeServerError(): Promise<void> {
		// fail silently
		return;
	}
}
