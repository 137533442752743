




import Vue from "vue";
import { Component} from 'vue-property-decorator';

@Component({
	components: {
	}
})
export default class IsFuture extends Vue {
	get label(): string {
		return 'is a past date';
	}
}
