






















import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import {FormNode, Form, FormType, FormNodeType, FormNodeFactory} from '@/services/api';
import PagesPanel from '@/components/pages/PagesPanel.vue';
import FieldsPanel from '@/components/fields/Panel.vue';
import FieldsLibrary from '@/components/library/FieldsLibrary.vue';
import { namespace } from 'vuex-class';

const formState = namespace('form');

@Component({
	components: {
		PagesPanel, FieldsPanel, FieldsLibrary
	},
	computed: {
	}
})
export default class FormEditor extends Vue {
	@Prop({default: false}) readonly ro!: boolean;
	@formState.State form !: Form;
	@formState.State currentPage !: FormNode;
	@formState.Mutation setCurrentPage !: (page: FormNode) => void;

	created(): void {
		// we can have a flash legacy form with no pages
		if (this.form.revision.rootNode.children.length === 0) {
			const newPage = FormNodeFactory(FormNodeType.page, 'New Page');
			// todo: make some kinda map for new node labels based on FormNode.type for Factory to automate this
			this.form.revision.rootNode.children.push(newPage);
		}
		this.setCurrentPage(this.form.revision.rootNode.children[0]);
	}

	get isTypeForm(): boolean {
		return this.form.type === FormType.form;
	}

	get pageLabel(): string  | null{
		if (!this.isTypeForm) {
			return 'Snippet';
		} else if (this.currentPage) {
			return this.currentPage.label;
		} 
		return 'Unknown';
	}
	
}
