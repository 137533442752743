





























import Vue from 'vue';
import { Component, VModel, Prop } from 'vue-property-decorator';
import Draggable from 'vuedraggable';

import * as elements from '@/components/elements/index';
import { FormNode, getElementConfig, FormNodeTypes } from '@/services/api';
import FieldSettings from '@/components/modals/FieldSettings.vue';
// import Sortable from 'sortablejs';
// import { SortableEvent } from 'sortablejs';

@Component({
	components: {...elements, Draggable, FieldSettings}
})
export default class Section extends Vue {
	@VModel() field !: FormNode;
	@Prop() position !: number;
	@Prop({ default: true}) ro!: boolean;
	
	getRenderComponent(type: FormNodeTypes): string {
		return getElementConfig(type).renderedElement;	
	}

	deleteNode(node: FormNode): void {
		this.field.children = this.field.children.filter(h => { return h != node;});

		this.$nextTick();
	}
}
