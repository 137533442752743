import axios, { AxiosResponse } from 'axios';
import {
	Form,
	Revision,
} from '@/services/api';
import {
	FBaseService,
	ResponseEnvelope,
	ResponseEnvelopeCollection,
	ExpandableFilter
} from '@kasasa/fbase-components/lib';
import FormFilter from './api/FormFilter';


export default class FormService extends FBaseService {
	findAll(clientId: number | string, filter: FormFilter): Promise<AxiosResponse<ResponseEnvelopeCollection<Form>>> {
		return this.apiClient.get(`/api/inmo-form/client/${clientId}/form`, {
			params: filter.getQuery()
		});
	}

	findGlobals(): Promise<AxiosResponse<ResponseEnvelopeCollection<Form>>> {
		// bypass session expired alert, regular find/findAll/etc. will trigger snackbars
		const apiClient = axios.create({});
		return apiClient.get(`/api/inmo-form/client/0/form`);
	}

	find(clientId: number | string, formId: number | string, filter: ExpandableFilter): Promise<AxiosResponse<ResponseEnvelope<Form>>> {
		return this.apiClient.get(`/api/inmo-form/client/${clientId}/form/${formId}`, {
			params: filter.getQuery()
		});
	}

	create(clientId: number | string, form: Form): Promise<AxiosResponse<ResponseEnvelope<Form>>> {
		return this.apiClient.post(`/api/inmo-form/client/${clientId}/form`, form);
	}

	update(clientId: number | string, formId: number | string, form: Form): Promise<AxiosResponse<ResponseEnvelope<Form>>> {
		return this.apiClient.put(`/api/inmo-form/client/${clientId}/form/${formId}`, form);
	}

	delete(clientId: number | string, formId: number | string): Promise<AxiosResponse<ResponseEnvelope<unknown>>>  {
		return this.apiClient.delete(`/api/inmo-form/client/${clientId}/form/${formId}`);
	}

	findAllRevisions(clientId: number | string, formId: number | string): Promise<AxiosResponse<ResponseEnvelopeCollection<Revision>>> {
		return this.apiClient.get(`/api/inmo-form/client/${clientId}/form/${formId}/revision`);
	}

	findRevision(clientId: number | string, formId: number | string, revId: number | string): Promise<AxiosResponse<ResponseEnvelope<Revision>>> {
		return this.apiClient.get(`/api/inmo-form/client/${clientId}/form/${formId}/revision/${revId}`);
	}

	duplicate(clientId: number | string, formId: number | string): Promise<AxiosResponse<ResponseEnvelope<Form>>> {
		return this.apiClient.post(`/api/inmo-form/client/${clientId}/form/${formId}/copy-job`, {});
	}

	export(clientId: number | string, formId: number | string): Promise<AxiosResponse<ResponseEnvelope<Form>>> {
		return this.apiClient.post(`/api/inmo-form/client/${clientId}/form/${formId}/export-job`, {});
	}

	import(clientId: number | string, formId: number | string): Promise<AxiosResponse<ResponseEnvelope<Form>>> {
		return this.apiClient.post(`/api/inmo-form/client/${clientId}/form/${formId}/import-job`, {});
	}
}
