






























import Vue from 'vue';
import { mapState } from 'vuex';
import { RouteName } from '@/router';
import { namespace } from 'vuex-class';
import { Component } from 'vue-property-decorator';

import { AuthGroupManager } from '@kasasa/fbase-components/lib';
import { KPortalHeader, KAppToolbar, KNoticeContainer, KAppToolbarBtn } from '@kasasa/fbase-components';

import FormImports from '@/components/modals/FormImports.vue';
import FormsAuthor from '@/store/FormsAuthor';

const auth = namespace('auth');

@Component({
	name: 'App',
	components: {
		KPortalHeader, KAppToolbar, KNoticeContainer, KAppToolbarBtn, FormImports
	},
	computed: {
		...mapState(['auth'])
	},
})
export default class App extends Vue {
	@auth.State('authManager') authManager!: AuthGroupManager;

	showImportModal = false;

	get auther(): FormsAuthor {
		return new FormsAuthor(this.authManager);
	}

	get isWide(): boolean {
		return !!this.$route.meta.wide;
	}

	get showAddBtn(): boolean {
		return this.$route.meta.isGlobal && this.$route.meta.showAdd && this.auther.canWriteGlobal();
	}

	get showImportBtn(): boolean {
		return !this.$route.meta.isGlobal && this.$route.meta.importable && this.auther.canWriteClient();
	}

	goToAdd(): void {
		if (this.$route.meta.isGlobal) {
			this.$router.push({name: RouteName.GLOBAL_ADD});
		} else {
			this.$router.push({name: RouteName.CLIENT_ADD, params: this.$route.params});
		}
	}

	launchImport(): void {
		this.showImportModal = true;
	}
}
