
















import { Component, Prop, VModel } from 'vue-property-decorator';
import Vue from 'vue';
import { DeprecatedFormNodeType, FormNodeType } from '@/services/api';
import { KReadOnly } from '@kasasa/fbase-components';

interface VSelectOption {
	text: string;
	value: string|number|null;
}

@Component({
	components: {
		KReadOnly
	}
})
export default class VariationSelect extends Vue {
	@VModel({default: 'default'}) template!: string;
	@Prop({default: ''}) readonly type!: string;
	@Prop({default: true}) readonly disabled!: boolean;

	get mappedOptions(): VSelectOption[] {
		let options: VSelectOption[] = [];

		switch (this.type) {
			case FormNodeType.input:
				options = [
					{text: "Normal", value: "default"},
					{text: "Date", value: "view/input.tpl/date"},
					{text: "Email", value: "view/input.tpl/email"},
					{text: "Number", value: "view/input.tpl/number"},
					{text: "Telephone", value: "view/input.tpl/telephone"},
					{text: "URL", value: "view/input.tpl/url"},
					{text: "Short Field", value: "view/input.tpl/leftLabelMedium"},
					{text: "Dollar Amount", value: "view/input.tpl/dollarAmount"}
				];
				break;

			case FormNodeType.select1900ThruCurrentYear:
			case FormNodeType.selectCurrentYearThruPlus25:
			case FormNodeType.selectCountry:
			case FormNodeType.selectCustom:
			case FormNodeType.selectDate:
			case FormNodeType.selectFundingAccountHolderName:
			case FormNodeType.selectState:
			case FormNodeType.selectSuffix:
			case FormNodeType.selectTitle:
			case FormNodeType.selectYesNo:
			case DeprecatedFormNodeType.selectCitizenship:
			case DeprecatedFormNodeType.selectDay:
			case DeprecatedFormNodeType.selectMonth:
				options = [
					{text: 'Normal', value: 'default'},
					{text: 'Full Width', value: 'view/select.tpl/fullWidth'}
				];
				break;

			case FormNodeType.radioGroupCustom:
			case FormNodeType.radioGroupYesNo:
			case DeprecatedFormNodeType.radioGroupCitizenship:
				options = [
					{text: 'Normal', value: 'default'},
					{text: 'Full Width', value: 'view/radioGroup.tpl/fullWidth'},
					{text: 'Full Width (paragraph)', value: 'view/radioGroup.tpl/fullWidthPLabel'}
				];
				break;

			case FormNodeType.section:
				options = [
					{text: 'Normal', value: 'default'},
					{text: 'No Label', value: 'view/section.tpl/emptyFieldset'}
				];
				break;

			case FormNodeType.checkbox:
				options = [
					{text: 'Normal', value: 'default'},
					{text: 'Full Width', value: 'view/checkbox.tpl/fullWidth'}
				];
				break;

			case FormNodeType.checkboxGroup : 
				options = [
					{text: 'Normal', value: 'default'},
					{text: 'Full Width', value: 'view/fieldgroup.tpl/checkboxesFullWidth'}
				];
				break;

			case FormNodeType.fundingAmount: 
			case FormNodeType.productFundingAmount: 
				options = [
					{text: "Normal", value: "default"},
					{text: "Single Field", value: "view/fieldgroup.tpl/unverifiedFundingAmount"}
				];
				break;

			case FormNodeType.overdraftSection:
				options = [
					{text: "Normal", value: "default"},
					{text: "Simple Radios", value: "view/section.tpl/overdraftVariant"}
				];
				break;

			case FormNodeType.accountHolderRadio:
			case FormNodeType.customHtml:
			case FormNodeType.customSnippet:
			case FormNodeType.demonstrableConsent:
			case FormNodeType.eligibilitySnippet:
			case FormNodeType.noCaptcha:
			case DeprecatedFormNodeType.applicantIdentification:
				options = [
					{text: 'Normal', value: 'default'}
				];
				break;


			case FormNodeType.applicantInfoReview:
				options = [
					{text: "Normal", value: "default"},
					{text: "Verification Headers", value: "view/applicantInformation.tpl/withVerifcationHeaders"}  // Typo here on porpoise ¯\_(ツ)_/¯
				];
				break;

			case FormNodeType.jointOow:
			case FormNodeType.oow:
				options = [
					{text: "Normal", value: "default"},
					{text: "With Question Labels", value: "view/section.tpl/oowWithQuestions"}
				];
				break;

			default: 
				options = [{
					text: "Deprecated or Not Implemented", value: "default"
				}];
				break;
		}

		return options;
	}

	variationFromTemplate(template: string): string {
		const option = this.mappedOptions.find((option: VSelectOption) => option.value === template);
		return option ? option.text : '';
	}
}
