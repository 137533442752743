








































import { Component, Prop, PropSync } from 'vue-property-decorator';
import {Form, FormType} from '@/services/api';
import Vue from 'vue';
import HIRT from '@/components/hirt';
import { KReadOnly, KText, KSelect} from '@kasasa/fbase-components';
import { required } from 'vuelidate/lib/validators';
import { RuleDecl } from 'vue/types/options';

@Component({
	components: {
		KReadOnly, KText, KSelect
	},
})
export default class FormHeader extends Vue implements HIRT {
	@PropSync('form') localForm!: Form;
	@Prop({ default: true }) readonly ro!: boolean;

	isMounted = false;
	typeOptions = [
		{text: 'Form', value: FormType.form},
		{text: 'Snippet', value: FormType.snippet},
	];

	mounted(): void {
		this.isMounted = true;
	}

	get formType(): string | null {
		return this.localForm.type ? (this.localForm.type === FormType.snippet ? 'Snippet' : 'Form') : null;
	}
	
	validations(): RuleDecl {
		return {
			localForm: {
				name: { required },
				type: { required }
			}
		};
	}

	hasErrors(): boolean {
		return this.$v.$anyError;
	}

	isDirty(): boolean {
		return this.$v.$anyDirty;
	}

	reset(): void {
		this.$v.$reset();
	}

	touch(): void {
		this.$v.$touch();
	}	

	get nameErrorMessage(): string | null {
		return this.isMounted ? (
			(this.$v.localForm.name?.$invalid && this.$v.localForm.name?.$dirty) ? 'Name is Required' : null
		) : null;
	}

	get typeRequired(): string | null {
		return this.isMounted ? (
			(this.$v.localForm.type?.$invalid && this.$v.localForm.type?.$dirty) ? 'Type is Required' : null
		) : null;
	}
}
