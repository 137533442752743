







import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import { AuthGroupManager } from '@kasasa/fbase-components/lib';
import { KUnauthorized } from '@kasasa/fbase-components';

import FormsAuthor from '@/store/FormsAuthor';

const auth = namespace('auth');

@Component({
	components: {
		KUnauthorized
	},
	computed: {
	},
})
export default class GlobalWorkspace extends Vue {
	@auth.State('authManager') authManager !: AuthGroupManager;
	@Prop() clientId !: string;

	get formsAuthor(): FormsAuthor {
		return new FormsAuthor(this.authManager);
	}

	get isAuthorized(): boolean {
		return this.formsAuthor.canReadGlobal();
	}
}
