











































import Vue from "vue";
import { Component, Prop } from 'vue-property-decorator';

@Component({
	components: {
	}
})
export default class KMenu extends Vue {
	@Prop({ default: () => [] }) menuList!: never[];
	@Prop({ default: 'Menu' }) readonly label!: string;
	@Prop({ default: 'label' }) readonly keyLabel!: string;

	menuClick(item: never): void {
		this.$emit('menu-click', item);
	}

}
