







import Vue from 'vue';
import { FormNode } from '@/services/api';
import { Component, Prop, VModel } from 'vue-property-decorator';
import BaseProperties from '@/components/properties/BaseProperties.vue';

@Component({
	components: { BaseProperties }
})
export default class DeprecatedProperties extends Vue {
	@VModel() el !: FormNode;
	@Prop({default: true}) ro !: boolean;
}
