






































































import Vue from "vue";
import { Component, Prop, VModel, Emit } from 'vue-property-decorator';
import { Dialog, NoticeResponse } from "@kasasa/fbase-components/lib";
import { FormNode } from "@/services/api";
import PageSettings from "@/components/modals/PageSettings.vue";

@Component({
	components: {
		PageSettings
	}
})
export default class PageListItem extends Vue {
	@VModel({default: () => ({})}) localPage!: FormNode;
	@Prop({ default: true }) ro!: boolean;

	// @todo: replace with vuex store
	@Prop() readonly currentPage!: FormNode | null;

	displaySettingsModal = false;

	get actionsDisabled(): boolean {
		return this.localPage.id !== this.currentPageId;
	}

	get currentPageId(): string | null {
		return this.currentPage ? this.currentPage.id : null;
	}

	async removePage(): Promise<void> {
		const dialog = new Dialog(
			'Delete this page?',
			`Are you sure you want to delete the page "${this.localPage.label}"? Caution: this can't be undone.`,
			'DELETE'
		);

		dialog.setDeclineLabel('CANCEL')
			.setDismissable(false);

		const res = await this.$store.dispatch('notices/add', dialog);
		switch (res) {
			case NoticeResponse.ACCEPT:
				this.deletePage();
				break;
			case NoticeResponse.DECLINE:
			default:
				// do nothing
				break;	
		}
	}

	@Emit()
	deletePage(): FormNode {
		return this.localPage;
	}

	@Emit()
	selectPage(): FormNode {
		return this.localPage;
	}
	
}
