


























import { FormNode, getElementConfig } from '@/services/api';
import Vue from 'vue';
import { Component, VModel } from 'vue-property-decorator';

import BaseElement from './BaseElement.vue';

@Component({
	components: {
		BaseElement
	}
})
export default class RadioGroupElement extends Vue {
	@VModel() el !: FormNode;

	get options(): string[] {
		const config = getElementConfig(this.el.type);
		if (config.data?.options) {
			return config.data.options as string[];
		}

		return this.el.options?.map((option) => option.label) || [];
	}
}
