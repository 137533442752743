

















import Vue from "vue";
import { Component, Prop, VModel } from 'vue-property-decorator';
import { KReadOnly } from '@kasasa/fbase-components';
import { ApplicantType, FormSpecification } from '@/services/api';
import Specification from '../Specification.vue';

@Component({
	components: {
		KReadOnly,
	}
})
export default class ApplicantSelect extends Vue {
	@VModel({default: () => ({} as Specification) }) specification!: FormSpecification;
	@Prop({default: true}) readonly disabled!: boolean;

	get label(): string {
		return 'OOW questions exist';
	}

	applicantOptions = [
		{text: 'for Primary Applicant', value: ApplicantType.PRIMARY},
		{text: 'for Joint Applicant', value: ApplicantType.JOINT},
	];

	get value1(): string {
		return this.specification.applicantType || ApplicantType.PRIMARY;
	}

	set value1(value: string) {
		this.specification.applicantType = value as ApplicantType;
	}

	get roValue(): string {
		const selectedOption = this.applicantOptions.find((option) => {
			return option.value == this.value1;
		});
		return selectedOption?.text || this.applicantOptions[0].text;
	}
}
