




import Vue from "vue";
import { Component} from 'vue-property-decorator';

@Component({
	components: {
	}
})
export default class IsAtLeast18YearsAgo extends Vue {
	get label(): string {
		return 'is at least 18 years ago';
	}
}
