











































import Vue from 'vue';
import { Component, Watch, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { NavigationGuardNext, Route } from 'vue-router';

import { KSpinner } from '@kasasa/fbase-components';

import FormListTable from '@/components/FormListTable.vue';
import FormService from '@/services/FormService';

import { Form } from '@/services/api';
import { ListTableFilters } from '@/types/ListTableFilters';
import { ListTableOptions } from '@/types/ListTableOptions';
import FormFilter from '@/services/api/FormFilter';

const globals = namespace('globals');

@Component({
	components: {
		FormListTable,
		KSpinner,
	},
})
export default class ListFormsPage extends Vue {
	@Prop() clientId !: string;
	@Prop({ default: false }) readonly isGlobal !: boolean;
	@globals.Action fetchGlobals !: () => Promise<Form[]>;
	
	status: 'loading' | 'loaded' | 'empty' = 'loading';

	formSvc: FormService = new FormService(this.$store);

	forms: Form[] = [];

	@Watch('$route', { immediate: true, deep: true })
	onRouteChange(): void {
		this.loadForms();
	}

	beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext): void {
		next((vm) => {
			if (from.params.clientId !== to.params.clientId) {
				vm.$store.dispatch('table/reset');
			}
		});
	}

	beforeRouteUpdate(to: Route, from: Route, next: NavigationGuardNext): void {
		if (from.params.clientId !== to.params.clientId) {
			this.$store.dispatch('table/reset');
		}
		next();
	}

	loadForms(): void {
		this.status = 'loading';
		this.forms = [];
		this.formSvc.findAll(this.clientId, new FormFilter()).then(resp => {
			this.forms = resp?.data?.data as Form[];
			this.status = this.forms && this.forms.length > 0 ? 'loaded' : 'empty';
		});
		this.fetchGlobals();
	}

	resetTableFilters(): void {
		this.$store.dispatch('table/reset');
	}

	clearTableFilters(): void {
		this.$store.dispatch('table/clearExtraFilters');
	}

	get tableOptions(): ListTableOptions {
		return this.$store.state.table.tableOptions;
	}

	set tableOptions(options: ListTableOptions) {
		this.$store.commit('table/SET_TABLE_OPTIONS', options);
	}

	get tableFilters(): ListTableFilters {
		return this.$store.state.table.tableFilters;
	}

	set tableFilters(filters: ListTableFilters) {
		this.$store.commit('table/SET_TABLE_FILTERS', filters);
	}
}
