




































































































import Vue from "vue";
import { Component, Prop, VModel } from 'vue-property-decorator';
import { upperFirst, toString } from 'lodash';
import { namespace } from 'vuex-class';
import moment from 'moment';
import { RouteName } from "@/router";
import { Location } from "vue-router";

import { Alert, NoticeClass } from "@kasasa/fbase-components/lib";
import { KSpinner } from '@kasasa/fbase-components';

import FormService from "@/services/FormService";
import { Form, FormType } from "@/services/api";

const globals = namespace('globals');

@Component({
	components: {
		KSpinner
	}
})
export default class FormImports extends Vue {
	@VModel({type: Boolean, default: false}) displayModal!: boolean;
	@Prop() clientId !: string;
	
	isLoaded = false;
	search = '';
	formSvc = new FormService(this.$store);
	
	@globals.Action fetchGlobals !: () => Promise<Form>;
	@globals.State globals !: Form[];

	async created(): Promise<void> {
		await this.fetchGlobals()
			.finally(() => {
				this.isLoaded = true;
			});
	}

	searchHighlight(value: string|number): string | number  {
		if (!this.search.length || value === null) {
			return value;
		}
		
		return value.toString().replace(
			RegExp(this.search, 'ig'),
			(matchText) => '<strong style="color: #000;">' + matchText + '</strong>'
		);
	}

	convertType(type: FormType): string {
		return upperFirst(type);
	}

	convertTime(time: number): string {
		return moment.unix(time).format('MM/DD/YYYY h:mm A');
	}

	handleRowClick(row: Form): void {
		this.formSvc.import(this.clientId, toString(row.id))
			.then((resp) => {
				const newForm: Form = resp.data.data;
				const route = {
					params: { clientId: newForm.clientId, formId: newForm.id }, 
					name: RouteName.CLIENT_EDIT
				} as Location;
				this.$router.push(route);
			})
			.catch(() => {
				const fail = new Alert(`There was an error exporting ${row.name}.`, NoticeClass.ERROR);
				fail.setTimeout(6000);
				this.$store.dispatch('notices/add', fail);
			})
			.finally(() => {
				this.displayModal = false;
			});
	}

}
