




































import { FormNode } from '@/services/api';
import Vue from 'vue';
import { Component, VModel } from 'vue-property-decorator';

import BaseElement from './BaseElement.vue';

@Component({
	components: {
		BaseElement
	}
})
export default class OverdraftSectionElement extends Vue {
	@VModel() el !: FormNode;

	get options(): string[] {
		return [
			'I/We confirm that I have read the disclosure above and <br><strong>I DO want CLIENT_NAME to authorize and pay overdrafts</strong> on my ATM and everyday debit card transactions.',
			'I/We confirm that I have read the disclosure above and <br><strong>I DO NOT want CLIENT_NAME to authorize and pay overdrafts</strong> on my ATM and everyday debit card transactions.'
		];
	}
}
