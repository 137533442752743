




















import Vue from 'vue';
import { FormNode } from '@/services/api';
import { Component, Prop, VModel } from 'vue-property-decorator';
import BaseProperties from '@/components/properties/BaseProperties.vue';
import NormalStepTreeSelect from '@/components/menus/NormalStepTreeSelect.vue';
import OptionsManager from '@/components/managers/OptionsManager.vue';
import { KCheckbox } from '@kasasa/fbase-components';

@Component({
	components: {
		BaseProperties,
		NormalStepTreeSelect,
		OptionsManager,
		KCheckbox,
	}
})
export default class CheckboxGroupProperties extends Vue {
	@VModel() el !: FormNode;
	@Prop({default: true}) ro !: boolean;
}
