




import Vue from "vue";
import { Component, Prop, VModel } from 'vue-property-decorator';
import { FormSpecification } from '@/services/api';
import * as ConditionModules from '@/components/specifications/conditions/index';
import Specification from '../Specification.vue';

@Component({
	components: {
		...ConditionModules,
	}
})
export default class IntegerRange extends Vue {
	@VModel({default: () => ({} as Specification) }) specification!: FormSpecification;
	@Prop  ({default: true}) readonly disabled!: boolean;

	get label(): string {
		return 'is between';
	}
}
