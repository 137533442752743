

















import { Component, Prop, VModel } from 'vue-property-decorator';
import Vue from 'vue';

import { namespace } from 'vuex-class';

import { Treeselect as TreeSelect } from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';

import { NormalStep } from '@/services/api';
import { TreeSelectOption } from '@/types/TreeSelectOption';

const normals = namespace('normals');

@Component({
	components: {
		TreeSelect
	},
})
export default class NormalStepTreeSelect extends Vue {
	@VModel({}) fkNormalStep !: string | number | null;
	@Prop({default: true}) ro !: boolean;
	@Prop({default: 'Normal Step'}) label !: string;

	@normals.Action('fetchSteps') fetchSteps!: () => Promise<NormalStep[]>;
	@normals.State('steps') steps !: NormalStep[];

	created(): void {
		this.fetchSteps();
	}

	normalStepMapper(node: NormalStep): TreeSelectOption<NormalStep> {
		const nn: TreeSelectOption<NormalStep> = {
			id: node.id,
			label: node.name || '',
			isDisabled: !! node.isAbstract
		};

		if (node.children && node.children.length >= 1) {
			nn.children = node.children;
		} else {
			delete(nn.children);
		}
		return nn;
	}

}
