import Vue from 'vue';
import VueRouter, { Route } from 'vue-router';
import { Dictionary } from 'vue-router/types/router';
import { KSelectFi } from '@kasasa/fbase-components';
import ListFormsPage from '@/pages/ListFormsPage.vue';
import FormPage from '@/pages/FormPage.vue';
import HistoryPage from '@/pages/HistoryPage.vue';
import GlobalWorkspacePage from '@/pages/GlobalWorkspace.vue';
import { GlobalWorkspaceRoutes, NonGlobalWorkspaceRoutes } from '@kasasa/fbase-components/lib';

Vue.use(VueRouter);

const mergeMeta = (route: Route): Dictionary<string> => {
	return { ...route.params, ...route.meta };
};

export const RouteName = {
	GLOBAL_HOME: 'GlobalHome',
	GLOBAL_ADD: 'GlobalAdd',
	GLOBAL_EDIT: 'GlobalEdit',
	GLOBAL_HISTORY: 'GlobalHistory',
	HOME: 'Home',
	CLIENT_HOME: 'ClientHome',
	CLIENT_ADD: 'ClientAdd',
	CLIENT_EDIT: 'ClientEdit',
	CLIENT_HISTORY: 'ClientHistory'
};

const routes = [
	GlobalWorkspaceRoutes([{
		path: '/global',
		component: GlobalWorkspacePage,
		props: mergeMeta,
		meta: {
			clientId: 0,
			isGlobal: true
		},
		children: [
			{
				path: '',
				name: RouteName.GLOBAL_HOME,
				component: ListFormsPage,
				props: mergeMeta,
				meta: {
					clientId: 0,
					isGlobal: true,
					showAdd: true
				},
			},
			{
				path: 'form/add',
				name: RouteName.GLOBAL_ADD,
				component: FormPage,
				props: mergeMeta, 
				meta: {
					mode: 'add',
					wide: true,
					clientId: 0,
					isGlobal: true
				}
			},
			{
				path: 'form/:formId',
				name: RouteName.GLOBAL_EDIT,
				component: FormPage,
				props: mergeMeta, 
				meta: {
					mode: 'edit',
					wide: true,
					clientId: 0,
					isGlobal: true
				}
			},
			{
				path: 'form/:formId/history/:revId',
				name: RouteName.GLOBAL_HISTORY,
				component: HistoryPage,
				props: mergeMeta, 
				meta: {
					wide: true,
					clientId: 0,
					isGlobal: true
				}
			},
		]
	}]),
	NonGlobalWorkspaceRoutes([{
		path: '/',
		name: RouteName.HOME,
		component: KSelectFi
	},
	{
		path: '/:clientId([1-9]\\d*)',
		name: RouteName.CLIENT_HOME,
		component: ListFormsPage,
		props: mergeMeta, 
		meta: {
			showAdd: true,
			importable: true
		}
	},
	{
		path: '/:clientId([1-9]\\d*)/form/add',
		name: RouteName.CLIENT_ADD,
		component: FormPage,
		props: mergeMeta, 
		meta: {
			mode: 'add',
			wide: true
		}
	},
	{
		path: '/:clientId([1-9]\\d*)/form/:formId',
		name: RouteName.CLIENT_EDIT,
		component: FormPage,
		props: mergeMeta, 
		meta: {
			mode: 'edit',
			wide: true
		}
	},
	{
		path: '/:clientId([1-9]\\d*)/form/:formId/history/:revId',
		name: RouteName.CLIENT_HISTORY,
		component: HistoryPage,
		props: mergeMeta, 
		meta: {
			wide: true
		}
	},
	{
		path: '*',
		redirect: { name: RouteName.HOME }
	}])
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
});

export default router;