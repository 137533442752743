var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"field-list-item",class:{
		'base-element': _vm.base,
		'dark-background': _vm.position % 2 == 1,
		'disabled-element': !_vm.el.isEnabled
	}},[_c('v-col',{staticClass:"py-2",attrs:{"cols":"1"}},[(_vm.isCollapsable)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('toggle-section')}}},[_c('v-icon',{staticClass:"my-2 text-px-18 collapse-icon",attrs:{"color":"#999"}},[_vm._v(" fas fa-caret-down ")])],1):_vm._e(),(!_vm.ro && !_vm.isCollapsable)?_c('v-icon',{staticClass:"my-3 text-px-14",attrs:{"color":"#ccc"}},[_vm._v(" fas fa-grip-vertical ")]):_vm._e()],1),(_vm.showLabel)?_c('v-col',{staticClass:"base-label pl-0 py-1 mt-2 mb-1",attrs:{"cols":"3"}},[_vm._t("label",[_c('span',{staticClass:"text-subtitle-1 font-weight-medium base-element-label",domProps:{"innerHTML":_vm._s(_vm.label)}})])],2):_vm._e(),_c('v-col',{staticClass:"base-body"},[_vm._t("body")],2),_c('v-col',{staticClass:"py-2",attrs:{"cols":"2","align":"right","justify":"center"}},[(_vm.hasRequired && !_vm.ro)?_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.toggleRequired}},[_c('v-icon',{staticClass:"mx-0 my-0 text-px-14",attrs:{"color":_vm.asteriskColor}},[_vm._v(" fas fa-asterisk ")])],1):(_vm.hasRequired && _vm.ro)?_c('v-icon',{staticClass:"mx-2 my-0 text-px-14",attrs:{"color":_vm.asteriskColor}},[_vm._v(" fas fa-asterisk ")]):_vm._e(),_c('v-dialog',{attrs:{"width":"806","attach":".field-list-item","retain-focus":_vm.retainFocus},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
	var on = ref.on;
	var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-0 mx-n1 my-0",attrs:{"icon":"","color":"#999"}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"text-px-14"},[_vm._v(" fas fa-cog ")])],1)]}}]),model:{value:(_vm.displaySettingsModal),callback:function ($$v) {_vm.displaySettingsModal=$$v},expression:"displaySettingsModal"}},[_c('field-settings',{staticClass:"no-drag",attrs:{"is-displayed":_vm.displaySettingsModal,"ro":_vm.ro},on:{"update:isDisplayed":function($event){_vm.displaySettingsModal=$event},"update:is-displayed":function($event){_vm.displaySettingsModal=$event}},model:{value:(_vm.el),callback:function ($$v) {_vm.el=$$v},expression:"el"}})],1),(!_vm.ro)?_c('v-btn',{staticClass:"mx-0 mx-n1 my-0",attrs:{"color":"#999","icon":""}},[_c('v-icon',{staticClass:"text-px-14",on:{"click":function($event){return _vm.$emit('delete-node', _vm.el)}}},[_vm._v(" fas fa-trash-alt ")])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }