




import Vue from "vue";
import { Component} from 'vue-property-decorator';

@Component({
	components: {
	}
})
export default class IntegerValue extends Vue {
	get label(): string {
		return 'is a whole number';
	}
}
