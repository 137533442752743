









import Vue from 'vue';
import { FormNode } from '@/services/api';
import { Component, Prop, VModel } from 'vue-property-decorator';
import * as properties from '@/components/properties/index';
import ActionsManager from '@/components/managers/ActionsManager.vue';


@Component({
	components: {
		...properties,
		ActionsManager
	}
})
export default class Actions extends Vue {
	@VModel() page !: FormNode;
	@Prop({default: true}) ro !: boolean;

}
