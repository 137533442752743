









import Vue from "vue";
import { Component, Prop, VModel } from 'vue-property-decorator';
import { FormSpecification } from '@/services/api';
import { SpecificationType } from '@/services/api/FormSpecification';
import * as ConditionModules from '@/components/specifications/conditions/index';
import FieldsListTreeSelect from '@/components/menus/FieldsListTreeSelect.vue';
import Specification from '../Specification.vue';

@Component({
	components: {
		...ConditionModules,
		FieldsListTreeSelect
	}
})
export default class ValueLength extends Vue {
	@VModel({default: () => ({} as Specification) }) specification!: FormSpecification;
	@Prop({default: true}) readonly disabled!: boolean;
	@Prop({default: null}) boundFormNodeId!: number;
	@Prop({default: SpecificationType.activator}) readonly selectType!: SpecificationType;

	get label(): string {
		return 'matches the field';
	}
}
