





























import { FormNode, FormNodeType } from '@/services/api';
import Vue from 'vue';
import { Component, VModel } from 'vue-property-decorator';

import BaseElement from './BaseElement.vue';

@Component({
	components: {
		BaseElement
	}
})
export default class OowElement extends Vue {
	@VModel() el !: FormNode;

	get applicant(): string {
		return this.el.type === FormNodeType.oow ? 'Primary' : 'Joint';
	}
}
