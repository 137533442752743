























import Vue from 'vue';
import { Component, VModel } from 'vue-property-decorator';

import { FormNode } from '@/services/api';
import BaseElement from './BaseElement.vue';
import SnippetSelect from '@/components/menus/SnippetSelect.vue';

@Component({
	components: {
		BaseElement,
		SnippetSelect
	}
})
export default class CustomSnippetElement extends Vue {
	@VModel() el !: FormNode;
}
