














































































import Vue from "vue";
import { Component, Prop, VModel } from 'vue-property-decorator';
import draggable from "vuedraggable";
import { KReadOnly, KCardHeaderBtn } from '@kasasa/fbase-components';
import { KInputValidate as KValidate } from '@kasasa/fbase-components/lib';
import { DeprecatedFormNodeType, FormNodeOption, FormNodeType } from '@/services/api';

@Component({
	components: {
		draggable,
		KReadOnly,
		KCardHeaderBtn
	}
})
export default class OptionsManager extends Vue {
	@VModel({default: () => []}) options!: FormNodeOption[];
	@Prop({default: () => ''}) formNodeType!: FormNodeType | DeprecatedFormNodeType;
	@Prop({default: true}) readonly disabled!: boolean;

	get inputLabel(): string {
		return this.getLabelFromType().charAt(0).toUpperCase() + this.getLabelFromType().slice(1).toLowerCase() + ' Label';
	}

	get inputValidation(): KValidate[] {
		return [KValidate.required('Please complete this field.')];
	}

	getLabelFromType(): string {
		// strip anything past checkbox|radio|select
		return this.formNodeType.replace(/(?<=^checkbox|^radio|^select).*$/, '');
	}

	addOption(): void {
		this.options.push({ label: 'New ' + this.getLabelFromType().toLowerCase()});
	}

	deleteOption(index: number): void {
		this.options.splice(index, 1);
	}
}
