import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

import { AuthStore, NoticeStore, pendoPlugin } from '@kasasa/fbase-components/lib';
import ListTableStore from '@/store/ListTableStore';
import NormalStore from '@/store/modules/NormalStore';
import FormStore from './modules/FormStore';
import GlobalStore from './modules/GlobalStore';

export default new Vuex.Store({
	modules: {
		auth: AuthStore,
		notices: NoticeStore,
		table: ListTableStore,
		normals: NormalStore,
		form: FormStore,
		globals: GlobalStore
	},
	plugins: [pendoPlugin]
});
