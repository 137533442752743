

















import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import { Component, VModel, Prop } from 'vue-property-decorator';
import { Form } from '@/services/api';
import { namespace } from 'vuex-class';
import { Treeselect as TreeSelect } from '@riophae/vue-treeselect';
import Vue from 'vue';
import { KReadOnly } from '@kasasa/fbase-components';
import { LoadStatus } from '@/store/modules/FormStore';

const formStore = namespace('form');

interface VSelectOption {
	text: string;
	value: string|number|null;
}

@Component({
	components: {
		TreeSelect,
		KReadOnly,
	},
})
export default class SnippetSelect extends Vue {
	@VModel({default: null}) snippetId!: string;
	@Prop({default: true}) disabled !: boolean;
	@Prop({default: false}) noLabel !: boolean;

	@formStore.Action fetchSnippets!: () => Promise<Form[]>;
	@formStore.State snippets !: Form[];
	@formStore.State snippetStatus !: LoadStatus;

	created(): void {
		this.fetchSnippets();
	}

	get snippetsLoaded(): boolean {
		return this.snippetStatus === LoadStatus.set;
	}

	get mappedOptions(): VSelectOption[] {
		const snippetOptions: VSelectOption[] = [];
		
		this.snippets.forEach((form: Form) => {
			snippetOptions.push({
				value: form.id?.toString(),
				text : form.name,
			} as VSelectOption);
		});

		return snippetOptions;
	}

	get label(): string {
		return this.noLabel ? '' : 'Snippet';
	}

	get snippetName(): string {
		return this.snippets.find((snippet) => snippet.id == this.snippetId )?.name || '[Not Set]';
	}

}
