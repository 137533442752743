




import Vue from "vue";
import { Component, Prop } from 'vue-property-decorator';

@Component({
	components: {
	}
})
export default class DefaultHook extends Vue {
	@Prop({ default: '' }) label!: string;
	@Prop({ default: true }) disabled!: boolean;
}
