




import Vue from "vue";
import { Component } from 'vue-property-decorator';

@Component({
	components: {}
})
export default class NotPoBoxValue extends Vue {
	get label(): string {
		return 'is NOT a PO Box';
	}
}
