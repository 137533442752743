








































































import Vue from "vue";
import { Component, VModel, Prop } from 'vue-property-decorator';
import draggable from "vuedraggable";
import { FormHookFactory, FormHookType, FormNode } from '@/services/api';
import { ActionList, ActionModules, ActionOption } from '@/components/actions/index';
import KMenu from '@/components/KMenu.vue';

@Component({
	components: {
		draggable,
		...ActionModules,
		KMenu
	}
})
export default class ActionsManager extends Vue {
	@VModel() page!: FormNode;
	@Prop({ default: true }) readonly disabled!: boolean;

	actionList = ActionList;
	actionModules = ActionModules;

	actionModule(type: string): string {
		let moduleName = type.charAt(0).toUpperCase() + type.slice(1);
		
		if (Object.keys(this.actionModules).indexOf(moduleName) == -1) {
			moduleName = 'DefaultHook';
		}
		
		return moduleName;
	}

	getLabelFromType(type: FormHookType): string {
		return ActionList.find(action => action.type == type)?.label || 'Unknown type: ' + type;
	}

	addAction(option: ActionOption): void {
		this.page.postHooks.push(FormHookFactory(Number(this.page.id), option.type));
	}

	deleteAction(index: number): void {
		this.page.postHooks.splice(index, 1);
	}
}
