import { KTinyMceSettings } from '@kasasa/fbase-components/lib';
import { Settings } from 'tinymce';

const tinyMce: KTinyMceSettings & Settings = {
	height: 222,
	menubar: false,
	'max_height': 600,
	branding: false,
	resize: true,
	elementpath: false,
	'allow_script_urls': true,
	'extended_valid_elements': 'img[class=myclass|!src|border:0|alt|title|width|height|style],script[src|async|defer|type|charset]',
	'relative_urls': false,
	plugins: ["advlist autolink link image lists charmap print preview hr anchor pagebreak",
		"visualblocks visualchars code media nonbreaking",
		"table directionality powerpaste textpattern", 
		"autoresize"],
	'style_formats' : [
		{'title':'Header 1', 'format':'h1'},
		{'title':'Header 2', 'format':'h2'},
		{'title':'Header 3', 'format':'h3'},
		{'title':'Header 4', 'format':'h4'},
		{'title':'Header 5', 'format':'h5'},
		{'title':'Header 6', 'format':'h6'},
		{'title':'Paragraph', 'format':'p'},
		{'title':'Blockquote', 'format':'blockquote'},
		{'title':'Disclaimer', 'selector' :'p,span', 'classes' :'disclaimer'},
		{'title':'Text Variant 1', 'selector' :'p,span', 'classes' :'text-variant'},
		{'title':'Zero Padding Paragraph', 'selector' :'p,span', 'classes' :'noMargin'},
		{
			'title': 'Buttons', 
			'items': [{
				'title':'Primary Button', 
				'selector' :'a', 
				'classes' :'primary-button'
			},{
				'title':'Secondary Button', 
				'selector' :'a', 
				'classes' :'secondary-button'
			},{
				'title':'Popup', 
				'selector' :'a', 
				'classes' :'popup'
			},{
				'title':'Iframe', 
				'selector' :'a', 
				'classes' :'iframe'
			}]
		}
	],
	"link_class_list": [{
		title: "None",
		value: ""
	}, {
		title: "Confirm",
		value: "confirm"
	}, {
		title: "Warn",
		value: "warn"
	}, {
		title: "Email Confirm",
		value: "emailConfirm"
	}],
	toolbar: ['undo redo | styleselect | bold italic superscript subscript | alignleft aligncenter alignright | bullist numlist outdent indent',
		'anchor link unlink | hr image table media | pastetext code removeformat print'],
	'paste_as_text': false,
	'powerpaste_word_import': "clean",
	'powerpaste_html_import': "clean",
	'powerpaste_block-drop': true,
	'powerpaste_allow_local_images': false,
	'powerpaste_keep_unsupported_src': false
};

export {
	tinyMce
}; 
