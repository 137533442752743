









import { FormNode } from '@/services/api';
import Vue from 'vue';
import { Component, VModel } from 'vue-property-decorator';

import BaseCheckboxElement from './BaseCheckboxElement.vue';

@Component({
	components: {
		BaseCheckboxElement
	}
})
export default class CheckboxElement extends Vue {
	@VModel() el !: FormNode;
}
