























import { FormNode } from '@/services/api';
import Vue from 'vue';
import { Component, VModel, Prop } from 'vue-property-decorator';

import BaseElement from './BaseElement.vue';

@Component({
	components: {
		BaseElement
	}
})
export default class BaseInputElement extends Vue {
	@VModel() el !: FormNode;
	@Prop({ default: ''}) placeholder !: string;
}
