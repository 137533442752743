















































































































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { mapState } from 'vuex';
import { namespace } from 'vuex-class';
import { Location, NavigationGuardNext, RawLocation, Route } from 'vue-router';
import {
	KCardHeader,
	KCardFooter,
	KCardFooterBtn,
	KCardHeaderBtn,
	KSpinner,
} from '@kasasa/fbase-components';
import {
	AuthGroupManager,
	ExpandableFilter,
	SessionData,
	KCrumb,
	NoticeResponse,
	Dialog,
	Alert,
	NoticeClass,
} from '@kasasa/fbase-components/lib';

import FormHeader from '@/components/FormHeader.vue';
import FormEditor from '@/components/FormEditor.vue';
import FormHistory from '@/components/modals/FormHistory.vue';

import FormService from '@/services/FormService';
import { Form, FormFactory, Revision, FormHook, FormHookType, FormNode, FormType } from '@/services/api';
import { RouteName } from '@/router';
import HIRT from '@/components/hirt';
import FormsAuthor from '@/store/FormsAuthor';

const auth = namespace('auth');
const formState = namespace('form');

@Component({
	components: {
		FormHeader,
		FormEditor,
		FormHistory,
		KCardFooter,
		KCardFooterBtn,
		KCardHeader,
		KCardHeaderBtn,
		KSpinner,
	},
	computed: {
		...mapState(['auth']),
	},
})
export default class FormPage extends Vue implements HIRT {
	@auth.State('authManager') authManager !: AuthGroupManager;
	@auth.State('sessionData') sessionData !: SessionData;
	@formState.Action('init') formStateInit !: (form: Form) => void;
	@formState.Action resetCurrentPage !: () => Promise<void>;
	@formState.Getter formPages !: FormNode[];

	@Prop() clientId !: string;
	@Prop({ default: false }) readonly isGlobal !: boolean;
	@Prop() formId !: string;
	@Prop({ default: 'edit'} ) mode !: string;

	@formState.Mutation	setForm !: (form: Form) => void;
	@formState.State form !: Form;
	overlay = false;
	showExportTooltip = false;
	
	isLoaded = false;
	isHistoryLoaded = false;
	loadedData = '';
	loadedForm = '';

	history: Revision[] = [];
	showHistory = false;

	formSvc = new FormService(this.$store);

	get localForm(): Form {
		return this.form;
	}

	set localForm(val: Form) {
		this.setForm(val);
	}

	get formsAuthor(): FormsAuthor {
		return new FormsAuthor(this.authManager);
	}

	get ro(): boolean {
		if (this.isGlobal) {
			return !this.formsAuthor.canWriteGlobal();
		}
		return !this.formsAuthor.canWriteClient();
	}

	get showDelete(): boolean {
		return !this.ro && this.mode === 'edit';
	}

	get showHistoryBtn(): boolean {
		return this.mode !== 'add';
	}

	get showDuplicateBtn(): boolean {
		return !this.ro && this.mode == 'edit';
	}

	get showExportBtn(): boolean {
		return !this.isGlobal && this.formsAuthor.canWriteGlobal() && this.mode == 'edit';
	}

	setLoadedData(form: Form): void {
		this.loadedForm = JSON.stringify(form);
	}

	@Watch('$route', {immediate: true, deep: true})
	loadForm(): void {
		this.isLoaded = false;
		this.overlay = false;
		if (this.mode === 'add') {
			this.localForm = FormFactory(
				this.clientId,
				this.sessionData.id
			);
			this.formStateInit(this.localForm);
			this.isLoaded = true;
			this.setLoadedData(this.localForm);
		} else {
			this.formSvc
				.find(
					this.clientId,
					this.formId,
					new ExpandableFilter().expand('revision'),
				)
				.then(resp => {
					this.localForm = resp.data.data;
					this.formStateInit(this.localForm);
					this.isLoaded = true;
					this.setLoadedData(this.localForm);

					// for later
					this.loadHistory();
				})
				.catch(() => {
					// bypassing any checks
					this.localForm = {} as Form;
					this.setLoadedData(this.localForm);

					const unknown = new Alert(`There was a problem loading the form.`, NoticeClass.INFO);
					unknown.setTimeout(6000);
					this.$store.dispatch('notices/add', unknown);
					this.goToFormList();
				});
		}
	}

	loadHistory(): void {
		this.isHistoryLoaded = false;
		this.history = [];
		this.formSvc.findAllRevisions(this.clientId, this.formId)
			.then(resp => {
				this.history = resp.data.data;
				this.isHistoryLoaded = true;
			});
	}

	get title(): string {
		return this.mode == 'add' ? 'Add New Form' : 'Edit';
	}

	get crumbs(): KCrumb[] {
		const crumbs = [];
		crumbs.push({
			key: '0',
			text: 'Forms',
			disabled: false,
			link: true,
			exact: true,
			to: {
				name: this.isGlobal ? RouteName.GLOBAL_HOME : RouteName.CLIENT_HOME,
				params: { clientId: this.clientId },
			},
		});

		if (this.mode == 'add') {
			crumbs.push({
				key: '1',
				text: this.title,
				disabled: true,
			});
		} else {
			crumbs.push({
				key: '2',
				text: this.title,
				disabled: true,
			});
		}
		return crumbs;
	}

	get formHeader(): FormHeader {
		return this.$refs.formHeader as FormHeader;
	}

	saveAction(): Promise<void> | void {
		if (this.hasErrors()) {
			return;
		}

		return this.generateSavePromise().then(() => {
			this.loadHistory();
		});
	}

	saveCloseAction(): Promise<void> | void {
		if (this.hasErrors()) {
			return;
		}

		return this.generateSavePromise().then(() => {
			this.goToFormList();
		});
	}

	checkForLeadSubmit(): void {
		if (this.localForm.type === FormType.snippet) {
			return;
		}
		let submitted = false;
		this.formPages.forEach((page) => {
			page.postHooks.forEach((hook: FormHook) => {
				if (hook.type === FormHookType.submitToLeadQueue) {
					submitted = true;
				}
			});
		});

		if (!submitted) {
			const warned = new Alert(`This form is not set up to submit to lead manager.`, NoticeClass.WARN);
			warned.setTimeout(6000);
			this.$store.dispatch('notices/add', warned);
		}
		return;
	}

	generateSavePromise(): Promise<void> {
		const saved = new Alert(`${this.localForm.name} is successfully saved.`, NoticeClass.SUCCESS);
		saved.setTimeout(6000);

		this.checkForLeadSubmit();

		return new Promise((resolve, reject) => {
			if (this.mode === 'add') {
				this.formSvc.create(this.clientId, this.localForm)
					.then((resp) => {
						this.$store.dispatch('notices/add', saved);

						// to bypass the unsaved changes
						this.localForm = resp.data.data;
						this.setLoadedData(this.localForm);

						// will send to new FormPage, however saveAndClose will override and send to formlist
						const params = {...this.$route.params, formId: this.localForm.id || ''};
						const route = {params: params, name: this.isGlobal ? RouteName.GLOBAL_EDIT : RouteName.CLIENT_EDIT} as RawLocation;
						this.$router.push(route);

						resolve();
					})
					.catch(() => {
						const error = new Alert(`${this.localForm.name} failed to save.`, NoticeClass.ERROR);
						error.setTimeout(6000);
						this.$store.dispatch('notices/add', error);
						reject();
					});
			} else {
				this.localForm.revision.fkUser = this.sessionData.id;
				this.formSvc.update(this.clientId, this.formId, this.localForm)
					.then((resp) => {
						this.localForm = resp.data.data;
						
						this.$store.dispatch('notices/add', saved);

						// reset all the vuelidates and initial form data;
						this.reset();
						this.setForm(this.localForm);
						this.setLoadedData(this.localForm);
						this.resetCurrentPage();

						// saveAndClose will send to formlist on resolve
						resolve();
					})
					.catch(() => {
						const failed = new Alert(`${this.localForm.name} failed to save.`, NoticeClass.ERROR);
						failed.setTimeout(6000);
						this.$store.dispatch('notices/add', failed);
						reject();
					});

			}
		});
	}

	cancelAction(): void {
		// beforeRouteLeave() will detect unsaved changes
		this.goToFormList();
	}

	exportForm(): void {
		this.overlay = true;
		this.formSvc.export(this.clientId, this.formId)
			.then((resp) => {

				const success = new Alert(`${this.localForm.name} has been successfully exported.`, NoticeClass.SUCCESS);
				success.setTimeout(6000);
				this.$store.dispatch('notices/add', success);

				const newForm: Form = resp.data.data;

				// will send to new FormPage, unsaved changes will get caught.
				const route = {
					params: { clientId: newForm.clientId, formId: newForm.id }, 
					name: RouteName.GLOBAL_EDIT
				} as Location;
				this.$router.push(route);
			})
			.catch(() => {
				// might be 409, in which case we have a prepared snackbar
				const fail = new Alert(`There was an error exporting ${this.localForm.name}.`, NoticeClass.ERROR);
				fail.setTimeout(6000);
				this.$store.dispatch('notices/add', fail);
			})
			.finally(() => {
				this.overlay = false;
			});
	}

	duplicateForm(): void {
		this.overlay = true;
		this.formSvc.duplicate(this.clientId, this.formId)
			.then((resp) => {

				const success = new Alert(`${this.localForm.name} has been successfully duplicated.`, NoticeClass.SUCCESS);
				success.setTimeout(6000);
				this.$store.dispatch('notices/add', success);

				const newForm: Form = resp.data.data;

				// will send to new FormPage, unsaved changes will get caught.
				const route = {
					params: { clientId: newForm.clientId, formId: newForm.id }, 
					name: this.isGlobal ? RouteName.GLOBAL_EDIT : RouteName.CLIENT_EDIT
				} as Location;
				this.$router.push(route);
			})
			.catch(() => {
				// might be 409, in which case we have a prepared snackbar
				const fail = new Alert(`There was an error duplicating ${this.localForm.name}.`, NoticeClass.ERROR);
				fail.setTimeout(6000);
				this.$store.dispatch('notices/add', fail);
			})
			.finally(() => {
				this.overlay = false;
			});
	}

	async deleteForm(): Promise<void> {

		const dialog = new Dialog(
			'Delete this Form?',
			`Are you sure you want to delete the ${this.localForm.type} "${this.localForm.name}"? This ${this.localForm.type} may be in use on live sites. Caution: this can't be undone.`,
			'DELETE'
		);

		dialog.setDeclineLabel('CANCEL')
			.setDismissable(false);
		const success = new Alert(`${this.localForm.name} has been Deleted.`, NoticeClass.SUCCESS);

		success.setTimeout(6000);

		const res = await this.$store.dispatch('notices/add', dialog);
		switch (res) {
			case NoticeResponse.ACCEPT:
				this.overlay = true;
				this.formSvc.delete(this.clientId, this.formId)
					.then(() => {
						this.$store.dispatch('notices/add', success);

						// bypass unsaved changes (usually implicit section reorg)
						this.setLoadedData(this.localForm);
						this.goToFormList();
					})
					.catch(() => {
						// might be 409, in which case we have a prepared snackbar
						// if we check for it's being used in a block
					})
					.finally(() => {
						this.overlay = false;
					});
				break;
			case NoticeResponse.DECLINE:
			default:
				// do nothing
				// the modal closes
				break;
		}
	}

	goToFormList(): void {
		const route = {
			params: this.$route.params,
			name: this.isGlobal ? RouteName.GLOBAL_HOME: RouteName.CLIENT_HOME,
		} as Location;
		this.$router.push(route);
	}

	showHistoryModal(): void {
		this.showHistory = true;
	}

	async beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext): Promise<void> {
		if (this.isDirty()) {
			const dialog = new Dialog('Unsaved Changes', 'You have unsaved changes on this page that will be lost if you leave now. Are you sure?', 'LEAVE WITHOUT SAVING');
			dialog.setDeclineLabel('STAY ON THIS PAGE')
				.setDismissable(false);

			const res = await this.$store.dispatch('notices/add', dialog);
			switch (res) {
				case NoticeResponse.ACCEPT:
					this.reset();
					next();
					break;
				case NoticeResponse.DECLINE:
				default:
					// staying on the page
					break;
			}
		} else {
			this.reset(); 
			next();
		}
	}

	get isExportDisabled(): boolean {
		return this.isDirty();
	}

	// HIRT
	hasErrors(): boolean {
		let errors = false;

		this.formHeader.touch();

		errors = this.formHeader.hasErrors();

		if (errors) {
			const broken = new Alert('Unable to save. Please make sure all required fields are completed without errors.', NoticeClass.ERROR);
			this.$store.dispatch('notices/add', broken);
		}

		return errors;
	}

	isDirty(): boolean {
		const dirty = JSON.stringify(this.localForm) !== this.loadedForm;

		return dirty;
	}

	reset(): void {
		this.formHeader && this.formHeader.reset();
	}

	touch(): void {
		this.formHeader && this.formHeader.touch();
	}
}
