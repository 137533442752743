


























import { FormNode } from '@/services/api';
import Vue from 'vue';
import { Component, VModel } from 'vue-property-decorator';

import BaseElement from './BaseElement.vue';

@Component({
	components: {
		BaseElement
	}
})
export default class DemonstrableConsentElement extends Vue {
	@VModel() el !: FormNode;
}
