





























import { FormNode } from '@/services/api';
import Vue from 'vue';
import { Component, VModel } from 'vue-property-decorator';

import BaseElement from './BaseElement.vue';

@Component({
	components: {
		BaseElement
	}
})
export default class FundingAmountElement extends Vue {
	@VModel() el !: FormNode;

	get label(): string {
		return this.el.label?.replace(/\r\n|\r|\n/g, '<br/>') || '';
	}
}
