
























































































































import { FormNode, FormNodeType } from '@/services/api';
import Vue from 'vue';
import { Component, VModel, Prop } from 'vue-property-decorator';
import FieldSettings from '@/components/modals/FieldSettings.vue';


@Component({
	components: {FieldSettings}
})
export default class BaseElement extends Vue {
	@VModel() el !: FormNode;
	@Prop({default: true}) showLabel !: boolean;
	@Prop({default: true}) base !: boolean;
	@Prop() position !: number;
	@Prop({default: true}) hasRequired !: boolean;
	@Prop({default: true}) ro !: boolean;
	@Prop({default: null}) readonly defaultLabel !: string;

	displaySettingsModal = false;

	get asteriskColor(): string {
		return !this.el.optional ? 'red lighten-2' : '#999';
	}

	get label(): string {
		return this.el.label || this.defaultLabel;
	}

	// if true, doesn't let tinymce code editor work.
	get retainFocus(): boolean {
		return this.el.type !== FormNodeType.customHtml;
	}

	get isCollapsable(): boolean {
		return this.el.type == FormNodeType.section;
	}

	toggleRequired(): void {
		this.el.optional = !this.el.optional;
	}
}
