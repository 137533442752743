









































































































import Vue from 'vue';
import { FormNode, FormNodeType } from '@/services/api';
import { Component, Prop, VModel } from 'vue-property-decorator';
import NormalFieldTreeSelect from '@/components/menus/NormalFieldTreeSelect.vue';
import VariationSelect from '@/components/menus/VariationsSelect.vue';

export enum PropertyField {
	showInLeadManager = 'showInLeadManager',
	showInLeadManagerAs = 'showInLeadManagerAs',
	defaultMessage = 'defaultMessage',
	errorMessage = 'errorMessage',
	successMessage = 'successMessage',
	variation = 'variation',
}

@Component({
	components: {
		NormalFieldTreeSelect,
		VariationSelect
	}
})
export default class BaseProperties extends Vue {
	@VModel() el !: FormNode;
	@Prop({default: true}) ro !: boolean;
	@Prop({default: () => []}) hideFields !: PropertyField[];

	propertyField = PropertyField;

	get required(): boolean {
		this.el;
		return !this.el.optional;
	}

	set required(val: boolean) {
		this.el.optional = !val;
	}

	get hasExtras(): boolean {
		return !!this.$slots.extras;
	}

	get isPage(): boolean {
		return this.el.type === FormNodeType.page;
	}

	showProperty(propertyName: PropertyField): boolean {
		return !this.hideFields.includes(propertyName);
	}
}
