




















import Vue from "vue";
import { Component, Prop, VModel } from 'vue-property-decorator';
import { KReadOnly } from '@kasasa/fbase-components';
import { FormSpecification } from '@/services/api';
import { KInputValidate as KValidate } from '@kasasa/fbase-components/lib';
import Specification from '../Specification.vue';

@Component({
	components: {
		KReadOnly,
	}
})
export default class NumberInput extends Vue {
	@VModel({default: () => ({} as Specification) }) specification!: FormSpecification;
	@Prop({default: true}) readonly disabled!: boolean;

	get validationRequired(): KValidate[] {
		return [KValidate.required()];
	}

	get value1(): string|number|null {
		return this.specification.num || '';
	}

	set value1(value: string|number|null) {
		this.specification.num = value ? Number(value) : null;
	}
}
