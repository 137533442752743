




























































import Vue from "vue";
import { Component, Prop, VModel, PropSync } from 'vue-property-decorator';

import { ElementConfig, FormNode, getElementConfig, Tab } from '@/services/api';
import * as Tabs from '@/components/modals/tabs/index';

@Component({
	components: {
		...Tabs,
	}
})
export default class FieldSettings extends Vue {
	@VModel() el!: FormNode;
	@Prop({default: true}) readonly actionsDisabled!: boolean;
	@Prop({default: true}) readonly ro!: boolean;
	@PropSync('isDisplayed', { default: false }) displayModal!: boolean;

	// @todo: ?replace with vuex store?
	@Prop({default: () => ({})}) readonly currentPage!: FormNode;

	get config(): ElementConfig {
		return getElementConfig(this.el.type);
	}

	// starting with first tab.
	tab = 0;
	get tabs(): Tab[] {
		// To be built from individual form elements in future ticket
		return this.config.settingsTabs;
	}

	tabItemCount(tabComponent: Tab): number {
		let count = 0;

		switch(tabComponent) {
			case Tab.ACTIVATORS:
				count = this.el.conditions.length;
				break;

			case Tab.VALIDATORS:
				count = this.el.validators.length;
				break;
		}

		return count;
	}
}
