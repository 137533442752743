

















import { Component, Prop, VModel } from 'vue-property-decorator';
import Vue from 'vue';

import { Treeselect as TreeSelect } from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import { namespace } from 'vuex-class';

import { NormalField } from '@/services/api';
import { TreeSelectOption } from '@/types/TreeSelectOption';

const normals = namespace('normals');

@Component({
	components: {
		TreeSelect
	},
})
export default class NormalFieldTreeSelect extends Vue {
	@VModel({}) fkNormalField !: string | number | null;
	@Prop({default: true}) ro !: boolean;
	@Prop({ default: 'Normal Field'}) label !: string;

	@normals.Action
	fetchFields!: () => Promise<NormalField[]>;
	
	@normals.State
	fields !: NormalField[];

	created(): void {
		this.fetchFields();
	}

	normalFieldMapper(node: NormalField): TreeSelectOption<NormalField> {
		const nn: TreeSelectOption<NormalField> = {
			id: node.id,
			label: node.name || '',
			isDisabled: !! node.isAbstract,
			children: false
		};

		if (node.children && node.children.length >= 1) {
			nn.children = node.children;
		}
		return nn;
	}
}
