export interface FormHook {
	[id: string]: string | number | null;
	fkFroiFormNode: string | number | null;
	type: FormHookType | null;
	toEmailFkFroiFormNode: string | number | null;
	fromEmailTemplate: string | null;
	bodyTemplate: string | null;
	subjectTemplate: string | null;
}

export enum FormHookType {
	callEfunds = 'callEfunds',
	submitJointAnswers = 'submitJointAnswers',
	submitPrimaryAnswers = 'submitPrimaryAnswers',
	submitToLeadQueue = 'submitToLeadQueue',
	submitLeadRevision = 'submitLeadRevision',
	initializeApplicant = 'initializeApplicant',
	socureApplicant = 'socureApplicant',

	// specials
	sendEmail = 'sendEmail',
	sendLeadToEmail = 'sendLeadToEmail',
}

export function FormHookFactory(formNodeId: number, type: FormHookType): FormHook {
	return {
		id: null,
		fkFroiFormNode: formNodeId,
		type: type,
		toEmailFkFroiFormNode: null,
		fromEmailTemplate: null,
		bodyTemplate: null,
		subjectTemplate: null,
	};
}