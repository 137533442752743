import { FormHookType } from '@/services/api';
import DefaultHook from './DefaultHook.vue';
import SendEmail from './SendEmail.vue';
import SendLeadToEmail from './SendLeadToEmail.vue';

export const ActionModules = {
	DefaultHook,
	SendEmail,
	SendLeadToEmail,
};

export interface ActionOption {
	label: string;
	type: FormHookType;
}

export const ActionList: ActionOption[] = [
	{
		label: 'Submit applicant info to Debit Bureau',
		type: FormHookType.callEfunds,
	},
	{
		label: 'Submit applicant info to Meridian Link',
		type: FormHookType.initializeApplicant,
	},
	{
		label: 'Submit applicant info to Socure and Debit Bureau',
		type: FormHookType.socureApplicant,
	},
	{
		label: 'Submit joint out-of-wallet answers',
		type: FormHookType.submitJointAnswers,
	},
	{
		label: 'Submit Lead Revision',
		type: FormHookType.submitLeadRevision,
	},
	{
		label: 'Submit primary out-of-wallet answers',
		type: FormHookType.submitPrimaryAnswers,
	},
	{
		label: 'Send Email',
		type: FormHookType.sendEmail,
	},
	{
		label: 'Send Lead to Email',
		type: FormHookType.sendLeadToEmail,
	},
	{
		label: 'Submit to Lead Manager',
		type: FormHookType.submitToLeadQueue,
	},
];

export interface VariableOption {
	label: string,
	value: string,
}

export const VariableList: VariableOption[] = [
	{label:"Applicant Address", value:"APPLICANT_ADDRESS"},
	{label:"Applicant Email", value:"APPLICANT_EMAIL"},
	{label:"Applicant Name", value:"APPLICANT_NAME"},
	{label:"Applicant Phone", value:"APPLICANT_PHONE"},
	{label:"Client Name", value:"CLIENT_NAME"},
	{label:"Confirmation Page URL", value:"CONFIRMATION_PAGE_URL"},
	{label:"Home Page Link", value:"HOME_PAGE_LINK"},
	{label:"Product Name", value:"PRODUCT_NAME"},
	{label:"Product Page Link", value:"PRODUCT_PAGE_LINK"},
];