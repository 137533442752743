import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';

import { NormalField, NormalStep } from '@/services/api';
import NormalService from '@/services/NormalService';

@Module({
	namespaced: true,
})
export default class NormalStore extends VuexModule {

	steps: NormalStep[] = [];
	fields: NormalField[] = [];

	loadingSteps = false;
	loadingFields = false;

	@Mutation
	setSteps(payload: NormalStep[]): void {
		this.steps = payload;
	}

	@Mutation
	setFields(payload: NormalField[]): void {
		this.fields = payload;
	}

	@Mutation
	setLoadingSteps(payload: boolean): void {
		this.loadingSteps = payload;
	}

	@Mutation
	setLoadingFields(payload: boolean): void {
		this.loadingFields = payload;
	}

	@Action( {commit: 'setSteps' })
	async fetchSteps(): Promise<NormalStep[]> {

		if (this.loadingSteps) {
			return this.steps;
		}
		
		if (this.steps && this.steps.length > 0) {
			return this.steps;
		}

		// call service
		const normalSvc = new NormalService;

		this.context.commit('setLoadingSteps', true);
		
		const steps: NormalStep[] = await normalSvc.findAllNormalSteps()
			.then(resp => {

				this.context.commit('setLoadingSteps', false);
				return resp.data.data.children || [];
			})
			.finally(() => {

				this.context.commit('setLoadingSteps', false);
				return [];
			});
		
		return steps;
	}

	@Action({ commit: 'setFields'})
	async fetchFields(): Promise<NormalField[]> {

		if (this.loadingFields) {
			return this.fields;
		}
		
		if (this.fields && this.fields.length > 0) {
			return this.fields;
		}

		// call service
		const normalSvc = new NormalService;

		this.context.commit('setLoadingFields', true);
		
		const fields: NormalField[] = await normalSvc.findAllNormalFields()
			.then(resp => {

				this.context.commit('setLoadingFields', false);
				return resp.data.data.children || [];
			})
			.finally(() => {

				this.context.commit('setLoadingFields', false);
				return [];
			});
		
		return fields;
	}

}