































































import Vue from "vue";
import { namespace } from 'vuex-class';
import { Component, Prop } from 'vue-property-decorator';
import Draggable from 'vuedraggable';

import { FormNode, FormNodeFactory } from '@/services/api';
import { FieldOption } from '.';

const formState = namespace('form');


@Component({
	components: {
		Draggable
	}
})
export default class BaseField extends Vue {
	@Prop({ default: {} }) field!: FieldOption;
	@Prop({ default: true }) clickableLabel!: boolean;
	@formState.Mutation appendFieldToCurrentPage !: (newFormNode: FormNode) => void;

	fieldMenuOpen = false;

	get hasOptions(): boolean {
		return !!this.field.options && this.field.options.length > 0;
	}

	fieldButtonClick(fieldOption: FieldOption, isField: boolean): void {
		if (!isField) { // menu toggle click
			this.fieldMenuOpen = !this.fieldMenuOpen;
			return;
		}

		// append field
		const newFormNode = FormNodeFactory(fieldOption.type, 'New ' + fieldOption.label, fieldOption.preset);
		this.appendFieldToCurrentPage(newFormNode);
	}

	exchange(o: FieldOption): FormNode {
		this.fieldMenuOpen = false;
		return FormNodeFactory(o.type, 'New ' + o.label, o.preset);
	}
}
