




import Vue from "vue";
import { Component} from 'vue-property-decorator';

@Component({
	components: {
	}
})
export default class IsLessThan100YearsAgo extends Vue {
	get label(): string {
		return 'is less than 100 years ago';
	}
}
