
































import Vue from 'vue';
import { Component, Prop, VModel } from 'vue-property-decorator';
import { Treeselect as TreeSelect } from '@riophae/vue-treeselect';
import { namespace } from 'vuex-class';

import { KReadOnly } from '@kasasa/fbase-components';
import { Form, FormNode, isPageOrSection } from '@/services/api';
import { SpecificationType } from '@/services/api';
import { TreeSelectOption } from '@/types/TreeSelectOption';


const formState = namespace('form');

@Component({
	components: {
		TreeSelect,
		KReadOnly
	}
})
export default class FieldsListTreeSelect extends Vue {
	@VModel({default: null}) selectedFieldId!: number; // Selected form node
	@Prop({default: null}) boundFormNodeId!: number; // Properties for form node
	@Prop({default: SpecificationType.activator}) readonly selectType!: SpecificationType; // field type
	@Prop({default: 'Field'}) label!: string;
	@Prop({default: true}) readonly disabled!: boolean;
	@formState.State form !: Form;

	created(): void {
		this.checkSetValue();
	}

	get formPages(): FormNode[]{
		return this.form.revision.rootNode.children;
	}

	async checkSetValue(): Promise<void> {
		// wait for input change to occur
		await this.$nextTick();

		// if validator: selectedFieldId cannot be null, set to itself
		if (this.selectedFieldId == null && this.selectType == SpecificationType.validator) {
			this.selectedFieldId = this.boundFormNodeId;
		}
	}

	normalizer(field: FormNode): TreeSelectOption<FormNode> {
		let selectOption: TreeSelectOption<FormNode> = {
			id: field.id,
			label: '[field no longer exists]',
			isDisabled: true
		};

		if (field.type === undefined) {
			return selectOption;
		}

		// Activators cannot activate themselves
		const isActivatorAndSelf = this.selectType == SpecificationType.activator && Number(field.id) == this.boundFormNodeId;

		selectOption = {
			isDisabled: isPageOrSection(field.type) || isActivatorAndSelf,
			label: field.label || '[' + field.type +' label not set]',
			id: field.id,
			children: isPageOrSection(field.type) ? field.children : false,
		};

		return selectOption;
	}

	get readOnlyLabel(): string {
		let fields: FormNode[] = [];

		// create flat fields list
		this.formPages.some((page: FormNode) => {
			page.children.some((section: FormNode) => {
				fields = [...fields, ...section.children];
			});
		});

		let found: FormNode = {} as FormNode;
		fields.some((field) => {
			const fieldId = Number(field.id);
			if (fieldId == this.selectedFieldId) {
				found = field;

				// if this is the selected field and we're editing properties for this field
				if (fieldId == this.boundFormNodeId) {
					field.label = 'This field';
				}

				return true;
			}
		});

		return found.label || (this.selectedFieldId ? '[field no longer exists]' : '[Not Set]');
	}
}
