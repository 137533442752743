import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';

import { Form } from '@/services/api';
import FormService from '@/services/FormService';

import { LoadStatus } from './LoadStatus';

@Module({
	namespaced: true,
})
export default class NormalStore extends VuexModule {

	globals: Form[] = [];
	loadingStatus: LoadStatus = LoadStatus.UNSET;

	@Mutation
	setForms(payload: Form[]): void {
		this.globals = payload;
	}

	@Mutation
	setLoadingStatus(payload: LoadStatus): void {
		this.loadingStatus = payload;
	}

	@Action( {commit: 'setForms' })
	async fetchGlobals(): Promise<Form[]> {
		if (this.loadingStatus === LoadStatus.LOADING) {
			return [];
		}

		// call service
		const formSvc = new FormService;

		this.context.commit('setLoadingStatus', LoadStatus.LOADING);
		
		const forms: Form[] = await formSvc.findGlobals()
			.then(resp => {
				this.context.commit('setLoadingStatus', LoadStatus.SET);
				return resp.data.data || [];
			})
			.finally(() => {
				this.context.commit('setLoadingStatus', LoadStatus.UNSET);
				return [];
			});
		
		return forms;
	}

}