




































import Vue from "vue";
import { Component, Prop, VModel } from 'vue-property-decorator';
import { KReadOnly } from '@kasasa/fbase-components';
import { FormSpecification, SpecType } from '@/services/api';
import FieldsListTreeSelect from '@/components/menus/FieldsListTreeSelect.vue';
import { SpecificationType } from '@/services/api';
import * as SpecificationModules from '@/components/specifications/types/index';

interface VSelectOption {
	text: string;
	value: string|number|null;
}

@Component({
	components: {
		...SpecificationModules,
		KReadOnly,
		FieldsListTreeSelect,
	}
})
export default class Specification extends Vue {
	@VModel({default: () => ({} as Specification) }) specification!: FormSpecification;
	@Prop({default: true}) readonly disabled !: boolean;
	@Prop({default: SpecificationType.activator}) readonly selectType !: SpecificationType;

	get specTypeOptions(): VSelectOption[] {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const modules = SpecificationModules as any;
			
		const options = Object.keys(SpecificationModules).map((key) => {
			return {
				text : modules[key].options.computed.label.get(),
				value: key.charAt(0).toLowerCase() + key.slice(1)
			} as VSelectOption;
		});

		return options;
	}

	get specTypeLabel(): string {
		let label = '[Not Set]';

		this.specTypeOptions.some((option) => {
			if (this.specification.type == option.value) {
				label = option.text;
				return true;
			}
		});

		return label;
	}

	get specModule(): string {
		const moduleName = this.specification.type.charAt(0).toUpperCase() + this.specification.type.slice(1);
		return Object.keys(SpecificationModules).includes(moduleName) ? moduleName : SpecType.notEmptyValue;
	}
}
