import { render, staticRenderFns } from "./Section.vue?vue&type=template&id=4bad9fcc&scoped=true&"
import script from "./Section.vue?vue&type=script&lang=ts&"
export * from "./Section.vue?vue&type=script&lang=ts&"
import style0 from "./Section.vue?vue&type=style&index=0&id=4bad9fcc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4bad9fcc",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VExpandTransition } from 'vuetify/lib/components/transitions';
installComponents(component, {VExpandTransition})
