















































import Vue from "vue";
import { Component, Prop, VModel } from 'vue-property-decorator';
import { KReadOnly } from '@kasasa/fbase-components';
import { FormSpecification } from '@/services/api';
import { KInputValidate as KValidate } from '@kasasa/fbase-components/lib';
import Specification from '../Specification.vue';

@Component({
	components: {
		KReadOnly,
	}
})
export default class Range extends Vue {
	@VModel({default: () => ({} as Specification) }) specification!: FormSpecification;
	@Prop  ({default: true}) readonly disabled!: boolean;

	get validationRequired(): KValidate[] {
		return [KValidate.required()];
	}

	get valueMin(): string|number {
		return this.specification.min || '';
	}

	set valueMin(value: string|number) {
		this.specification.min = value ? Number(value) : null;
	}

	get valueMax(): string {
		return this.specification.max ? String(this.specification.max): '';
	}

	set valueMax(value: string) {
		this.specification.max = value.length ? Number(value) : null;
	}
}
