




























































import { FormHook } from '@/services/api';
import Vue from "vue";
import { Component, VModel, Prop } from 'vue-property-decorator';
import FieldsListTreeSelect from '@/components/menus/FieldsListTreeSelect.vue';
import { KText } from '@kasasa/fbase-components';
import { KInputValidate } from '@kasasa/fbase-components/lib';
import KMenu from '@/components/KMenu.vue';
import { VariableList, VariableOption } from '@/components/actions/index';

interface InputElement extends HTMLElement {
   selectionStart: number,
	selectionEnd: number,
	value: string,
}

@Component({
	components: {
		FieldsListTreeSelect,
		KText,
		KMenu,
	}
})
export default class SendEmail extends Vue {
	@VModel({ default: () => { return {}; }}) action !: FormHook;
	@Prop({ default: '' }) label!: string;
	@Prop({ default: true }) disabled!: boolean;

	variableList = VariableList;
	focusedInput: InputElement | null = null;
	updateProperty = '';
	textareaKey = 0; // force re-render on focus (along with autofocus attribute) to get proper height on render

	get validateInputRequired(): KInputValidate[] {
		return [KInputValidate.required()];
	}

	get validateInputIsEmail(): KInputValidate[] {
		return [KInputValidate.isEmail()];
	}

	insertVariable(option: VariableOption): void {
		if (!this.focusedInput) {
			return;
		}

		let newValue = this.focusedInput.value + option.value;
		if (this.focusedInput.selectionStart > -1) {
			var startPos = this.focusedInput.selectionStart;
			var endPos = this.focusedInput.selectionEnd;
			newValue = this.focusedInput.value.substring(0, startPos) + option.value + this.focusedInput.value.substring(endPos, this.focusedInput.value.length);
		}

		this.action[this.updateProperty] = newValue;
	}

	focusInput(e: FocusEvent, updateProperty: string): void {
		this.focusedInput = e.target as InputElement;
		this.updateProperty = updateProperty;
	}
}
