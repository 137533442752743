









import { FormNode } from '@/services/api';
import Vue from 'vue';
import { Component, VModel } from 'vue-property-decorator';

import BaseInputElement from './BaseInputElement.vue';

@Component({
	components: {
		BaseInputElement
	}
})
export default class PasswordElement extends Vue {
	@VModel() el !: FormNode;
}
